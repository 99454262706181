<script>
export default {
  name: 'CommonSize'
}
</script>
<script setup>
import { defineProps, defineAsyncComponent, provide, ref, computed, watch, nextTick, onMounted, onUnmounted } from 'vue'
import SizeGroup from './components/SizeGroup/index.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import SizeSelectList from './components/SizeSelectList/index.vue'
import OtherContentComp from './components/OtherContentComp/index.vue'
import ProductIntroMall from 'public/src/pages/goods_detail_v2/components/ProductIntroMall.vue'
import useSizeInfoIndex from './compositions/useSizeInfoIndex.js'
import useSizeTips from './compositions/useSizeTips.js'
import { getBubbleInfo, isBaseCodePriorityComplete } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'
import { getSizeDesc, formatHeightSize, getAbSizeGroup } from './utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import eventBus from 'public/src/pages/goods_detail_v2/utils/eventBus.js'
import { debounce } from '@shein/common-function'
import sizeGuideFromSceneMap from 'public/src/pages/goods_detail_v2/utils/sizeGuideFromSceneMap.js' 
import ProductIntroQuickShip from 'public/src/pages/goods_detail_v2/components/ProductIntroQuickShip.vue'
// import ProductIntroSizeFeedback from 'public/src/pages/goods_detail_v2/components/ProductIntroSizeFeedback.vue'
import SizeFeedBackEnter from 'public/src/pages/goods_detail_v2/components/Size/components/SizeFeedBackEnter/index.vue'
// import { OriginSizes } from 'public/src/pages/goods_detail_v2/constants'
const ProductSizeGuid = defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/ProductSizeGuid.vue'))
const SizeRecommend = defineAsyncComponent(() => import('./components/SizeRecommend/index.vue'))
/**
 * @param {Boolean} tipsReady 是否开始渲染tips
 * @param {String} mallCode 当前 mallCode
 * @param {String} currentUnit 当前尺码单位
 * @param {String} country 当前国家
 * @param {Object} saleAttrList 当前销售属性处理集合
 *  @param {Array} polyAttrSize 尺码属性集合
 *  @param {Array} skcSaleAttr 销售数量list
 *  @param {Array} skuList sku列表
 * @param {Object} dataMapCompose 完整尺码映射聚合
 *  @param {Object} dataMap 完整尺码库存映射
 *  @param {Array} dataMapOrder  完整尺码库存排序
 * @param {Object} localSize 本地尺码
 * @param {Object} sizeInfoDes 尺码信息描述
 * @param {Object} tackInfoDes 家居品类额外描述
 * @param {Object} language 语言
 * @param {Object} baseInfo 基础信息
 * @param {Object} skuMapCompose sku映射聚合
 * @param {Object} constant 全局基本常量
 *  @param {Boolean} cssRight 是否是反转样式
 *  @param {Boolean} isRw 是否是rowme
 *  @param {String} langPath 当前页面路径
 * @param {Object} relatedLocalSize 关联大小码
 * @param {Object} findSimilarBind 售罄推荐列表传参
 * @param {Object} tipsCombo tip需要的数据源
 * @param {Object} sizeRecommendData 尺码推荐数据
 * @param {Object} getOtherOptions 关联尺码数据
 * @param {Object} sizeModalComb 商品详情SizeGuide相关数据流
 * @param { Object } externalSizeInfoIndex 初始化传入选中的sizeInfoIndex, 优先级更高
 * @param { String } externalSkuCode 初始化传入选中的sku，只在初始化时执行，和externalSizeInfoIndex二选一传入
 * @param {Object} config 配置
 *  @param {String} from 当前组件来源
 *  @param {String} sizeSaleAttrId 销售属性ID(尺码)
 *  @param {String} eventClickId 销售属性埋点id
 *  @param {Boolean} checkNotSelectedTips 展示未选择尺码动画
 *  @param {Boolean} isNewSizeLocal 是否新尺码本地化
 *  @param {Boolean} showHightSize 是否展示高度尺码
 *  @param {Boolean} longsizeTowLine 长尺码是否两行展示
 *  @param {Boolean} isAllSoldOut 是否下架或者全部售罄
 *  @param {Boolean} showSizeGuideInTips sizeGuide入口是否在tips中
 *  @param {Boolean} showSizeGuideInTitle sizeGuide入口是否在title
 *  @param {Boolean} showAttrImgPopover 是否展示尺码图片
 *  @param {Boolean} isHitComplianceMode 是否合规模式
 */
const props = defineProps({
  mallCode: {
    type: String,
    default: '',
  },
  tspShowBuySizeTable: {
    type: Boolean,
    default: false,
  },
  currentUnit: {
    type: Number,
    default: -1
  },
  country: {
    type: String,
    default: '',
  },
  saleAttrOrderMap: {
    type: Object,
    default: () => null,
  },
  hotLabelMap: {
    type: Object,
    default: () => null,
  },
  saleAttrList: {
    type: Object,
    default: () => ({}),
  },
  dataMapCompose: {
    type: Object,
    default: () => ({}),
  },
  localSize: {
    type: Object,
    default: () => ({}),
  },
  sizeInfoDes: {
    type: Object,
    default: () => ({}),
  },
  tackInfoDes: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  baseInfo: {
    type: Object,
    default: () => ({}),
  },
  skuMapCompose: {
    type: Object,
    default: () => ({}),
  },
  constant: {
    type: Object,
    default: () => ({}),
  },
  relatedLocalSize: {
    type: Object,
    default: () => ({}),
  },
  findSimilarBind: {
    type: Object,
    default: () => ({}),
  },
  tipsCombo: {
    type: Object,
    default: () => ({}),
  },
  sizeRecommendData: {
    type: Object,
    default: () => ({}),
  },
  getOtherOptions: {
    type: Array,
    default: () => [],
  },
  sizeModalComb: {
    type: Object,
    default: () => ({}),
  },
  externalSkuCode: {
    type: String,
    default: '',
  },
  externalSizeInfoIndex: {
    type: Object,
    default: () => {},
  },
  isAllSoldOut: {
    type: Boolean,
    default: false,
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  getSkcEstimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  showQuickShip: {
    type: Boolean,
    default: false
  },
  deliveryFloorQuickshipTitle: {
    type: String,
    default: '',
  },
  isJpQuickShip: {
    type: Boolean,
    default: false
  },
  currentMall: {
    type: Object,
    default: () => ({}),
  },
  recommendedSizeData: {
    default: () => ({
      recommendedSize: null,
      braRecommendSizes: []
    }),
    type: Object,
  },
  isShowModalInfo: {
    type: Boolean,
    default: false
  },
  realTimeReady: {
    type: Boolean,
    default: false,
  },
  tipsReady: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits([
  'mallClick', 
  'compose', 
  'openQuickView', 
  'onChangeSelectCountry',
  'onChangeUnit',
  'onChangeHasSize',
  'handleClickSizeGroupItem',
  'autoGetCouponAtAddBag', 
  'showLogin',
  'openSizeGuide',
  'handleUpdateQuickShip',
  'onUpdateOldData',
  'onRecommendedSizeChange',
])
let sizeRefs = ref({})
const setSizeRefs = (name, key) => (e) => {
  if (name === undefined || key === undefined || e === undefined) return
  if (!sizeRefs.value[name]) sizeRefs.value[name] = {}
  sizeRefs.value[name][key] = e
}

let loadSizeGuideModal = ref(false)
let notSelectedTipsAnim = ref(false)
let currentSelectedCountry = ref('')
let sizePopoverPlacemen = ref([])
let bubbleInfo = ref({})
let foldBtn = ref([])
let foldIndex = ref(-1)
let showSizeGuideModal = ref(false)
let showSizeReommendModal = ref(false)
let openSizeGuideFromRecommend = ref(false)
let recommendSize = ref('')

const goodsId = computed(() => props.baseInfo?.goods_id || '')
const skuMap = computed(() => props.skuMapCompose?.skuMap || {})
const skuIdMap = computed(() => props.skuMapCompose?.skuIdMap)
const skuAllInfo = computed(() => props.skuMapCompose?.skuAllInfo || {})
const dataMapOrder = computed(() => props.dataMapCompose?.dataMapOrder || [])
const dataMap = computed(() => props.dataMapCompose?.dataMap || {})
const dataIdMap = computed(() => props.dataMapCompose?.dataIdMap)
const skcSaleAttr = computed(() => props.saleAttrList?.skcSaleAttr || [])
const skuList = computed(() => props.saleAttrList?.skuList || [])
const skcName = computed(() => props.saleAttrList?.skcName || '')
const polyAttrSize = computed(() => props.saleAttrList?.polyAttrSize || [])
const sizeUnit = computed(() => props.currentUnit == 1 ? 'inch' : 'cm')
// const feedbackBasicSizes = computed(() => {
//   if (!polyAttrSize.value.length) {
//     return []
//   }
//   const one = polyAttrSize.value?.[0]?.attr_value_name
//   for (let i = 0; i < OriginSizes.length; i++) {
//     if (OriginSizes[i].includes(one)) {
//       return OriginSizes[i]
//     }
//   }
//   return []
// })
const showQuickShipStatus = computed(() => props.config.showQuickShip && (props.baseInfo.mall?.[props.mallCode]?.skcQuickShip || props.isJpQuickShip ))
const heightSizeMap = computed(() => {
  const heightKey = props.language.SHEIN_KEY_PC_25650
  const baseCmOrg = props.sizeInfoDes?.basicAttribute?.base_code_info || []
  const baseInchOrg = props.sizeInfoDes?.basicAttribute?.base_code_info_inch || []
  const sizeMap = {
    cm: {},
    inch: {},
  }
  baseCmOrg.forEach((item) => {
    sizeMap['cm'][item.size] = formatHeightSize(item[heightKey] || '', 'CM')
  })
  baseInchOrg.forEach((item) => {
    sizeMap['inch'][item.size] = formatHeightSize(item[heightKey] || '', 'IN')
  })
  return sizeMap
})
const heightSizeObject = computed(() => heightSizeMap.value?.[sizeUnit.value] || {})  

const currentTabLocalIsActive = computed(() =>  {
  if(currentSelectedCountry.value){
    return currentSelectedCountry.value === props.config?.localSizeList?.country_code
  }
  if (typeof window === 'undefined') return false
  let lastSelectCountry = window.localStorage.getItem('last_select_country')
  if(lastSelectCountry && lastSelectCountry != props.config?.localSizeList?.country_code ){
    return false
  }
  if (!lastSelectCountry && ['old', 'local_size_no_default'].includes(props.config?.defaultlocalsize)) {
    return true
  }
  return false
})

const localSizeComputed = computed(() => {
  let country = currentSelectedCountry.value
  if(props.config?.showOnlyOneLocalSize){
    country = currentTabLocalIsActive.value ?  props.config?.localSizeList?.country_code : ''
  }
  const multiLocalSize = props.localSize?.size_rule_list || {}
  const hasMultiLocal = Object.keys(multiLocalSize)?.length > 0
  const localSizeRule = multiLocalSize?.[country] || []
  const localSizeMap = {}
  localSizeRule?.forEach?.((item) => {
    if (item.name == 'one-size') return
    localSizeMap[item.name] = item.correspond
  })
  const hasLocalSize = polyAttrSize.value?.some?.(item => localSizeMap[item.attr_value_name_en])
  return {
    multiLocalSize,
    hasMultiLocal,
    localSizeRule,
    localSizeMap,
    hasLocalSize,
    currentSelectedCountry,
    currentTabLocalIsActive
  }
})
const bubbleInfoComputed = computed(() => {
  if (Object.keys(bubbleInfo.value).length === 0) return {}
  const isShowBaseCode = isBaseCodePriorityComplete(skcSaleAttr.value, props.sizeInfoDes)
  const sizeDescMap = { inch: {}, cm: {} }
  polyAttrSize.value.forEach((item, index) => {
    const { attr_value_name_en } = item || {}
    sizeDescMap.inch[attr_value_name_en] = getSizeDesc(bubbleInfo.value.inch, index, isShowBaseCode, props.sizeInfoDes)
    sizeDescMap.cm[attr_value_name_en] = getSizeDesc(bubbleInfo.value.cm, index, isShowBaseCode, props.sizeInfoDes)
  })
  return {
    sizeInfoTitle: bubbleInfo.value?.title || '',
    sizeDescObj: sizeDescMap?.[sizeUnit.value] || {},
  }
})

const cccAttrGroups = computed(() => {
  if (props.config?.sizeRelatedTest) {
    return getAbSizeGroup(props.config?.sizeRelatedTest, props.sizeModalComb.productIntroData?.cccAttrGroups?.allSizeGroups || [])
  }
  return props.sizeModalComb.productIntroData?.cccAttrGroups?.cccList || []
})
const onlyRelatedGoods = computed(() => {
  if (props.config?.sizeRelatedTest) {
    return cccAttrGroups.value?.filter?.(item => !item.isCurrentGroup) || []
  }
  return props.sizeModalComb.productIntroData?.cccAttrGroups?.onlyRelatedGoods || []
})

const sizeGroupPosition = computed(() => {
  const { sizeGroupPositionAbt } = props.config
  // 关联商品不包含主商品
  if(['fold', 'size'].includes(sizeGroupPositionAbt)) return onlyRelatedGoods.value.length ? sizeGroupPositionAbt : ''
  const curSizeGroupIndex = cccAttrGroups.value?.findIndex?.((i) => i.isCurrentGroup)
  return cccAttrGroups.value.length >= 2 && curSizeGroupIndex !== -1 ? sizeGroupPositionAbt : ''
})

const hideOneSize = computed(() => {
  const { hideOneSize } = props.config
  return hideOneSize && polyAttrSize.value?.length == 1 && polyAttrSize.value[0]?.['attr_value_name_en'] == 'one-size' && skcSaleAttr.value?.length == 1 && skcSaleAttr.value[0]?.attr_id == 87
})
const isHasSize = computed(() => {
  const hasSizeSkc = skcSaleAttr.value.some(_ => _.attr_id == 87)
  return hasSizeSkc && !hideOneSize.value
})
const showSizeGroupAfterSizeCommon = computed(() => {
  if(sizeGroupPosition.value !== 'size' || !onlyRelatedGoods.value.length) return false
  return true
})
const showSizeGroupAfterSize = computed(() => {
  if(!showSizeGroupAfterSizeCommon.value || !isHasSize.value) return false
  return true
})
const showSizeGroupAfterSizeUndertake = computed(() => {
  if(!showSizeGroupAfterSizeCommon.value || isHasSize.value) return false
  return true
})

const recommendMySizeText = computed(() => {
  if (typeof window === 'undefined') return ''
  const { selfRecommendType } = props.sizeRecommendData || {}
  // 内衣尺码推荐
  if (selfRecommendType == 1) {
    // TODO: 统一用recommendSize,不知道有没有bug
    // const recommendMyBraSize = window.sessionStorage.getItem('recommendMyBraSize')
    // return recommendSize.value || recommendMyBraSize
    return recommendSize.value
  }
  // 本地尺码拼接
  if (!currentSelectedCountry.value) return recommendSize.value
  const find = localSizeComputed.value.localSizeRule?.find?.(el => el.name == recommendSize.value)?.correspond || ''
  const defaultlocalsize = props.config?.defaultlocalsize 
  const { isNewSizeLocal } = props.config || {}
  if (find) {
    if (isNewSizeLocal) {
      return defaultlocalsize !== 'local_size_no_default' ? `${find}(${recommendSize.value})` : `${find}`
    } else {
      return defaultlocalsize !== 'default_size_no_default' ?  `${recommendSize.value}(${find})` : `${recommendSize.value}`
    }
  }
  return recommendSize.value
})
/**
 * 埋点注入数据
 */
const analysisData = computed(() => {
  return {
    goodsId: props.baseInfo?.goods_id || '',
  }
})

const agenSizeHover = (e, isEnter) => {
  try {
    if (e) {
      let itemDom = typeof e?.target === 'string' ? document.querySelector(e.target) : e.target
      if (itemDom) {
        // 如果从配置中获取 from 属性且为真，则使用 document.body，否则找到最近的.sui-dialog__wrapper父元素
        const wrap = props.config?.from ? document.body : itemDom.closest('.sui-dialog__wrapper')
        if (isEnter) {
          let status = false
          let itemRect = itemDom?.getBoundingClientRect?.() // 获取元素的位置和大小信息
          let wrapRect = wrap?.getBoundingClientRect?.() // 获取容器的位置和大小信息

          // 这个 420 是 popover 宽度 320 加上预设的打小码 radio 宽度 100
          // 如果没有这个 100 会导致边界处理失效
          if (props.constant?.cssRight) {
            status = parseInt(wrapRect?.left) <= parseInt(itemRect?.left + itemRect?.width) - 420
          } else {
            status = parseInt(itemRect?.left) + 420 < parseInt(wrapRect?.width + wrapRect?.left)
          }
          return status
        }
      }
    }
  } catch(e) {
    /** 防止气泡阻塞点击尺码 */
  }
}
const mallClick = (mallInfo) => { emits('mallClick', mallInfo) }
const compose = (data) => { emits('compose', data) }
const { 
  initSizeInfoIndex, 
  chooseCheck, 
  skuInfo, 
  sizeInfoIndex,
  handleChoose,
  handleChooseTouch, 
  updateNotSelectedTips,
  notSelectedTips,
  userSelectSize,
  sizeEmit,
} = useSizeInfoIndex(
  props,
  { skuMap, dataMapOrder, dataMap, skcSaleAttr, skuList, skcName, skuAllInfo, dataIdMap, skuIdMap }, 
  { isMobile: props.config.isMobile, agenSizeHover, mallClick, compose }
)
const {
  tipsInfo,
  almostSoldOut,
} = useSizeTips(
  props,
  { skuInfo, skuList, skcSaleAttr, polyAttrSize }
)

const hideDisableAttrId = computed(() => {
  const optionLen = skcSaleAttr.value?.length
  if (optionLen >= 2 && props.config?.hideDisableOption) {
    const bothChoose = skcSaleAttr.value?.slice(0, optionLen - 1).every((item) => {
      const { attr_id } = item || {}
      return !!sizeInfoIndex.value?.[attr_id]?.attr_value_id
    })
    if (bothChoose) {
      return skcSaleAttr.value?.[optionLen - 1]?.attr_id || ''
    }
  }
  return '' 
})

const mallStocks = computed(() => {
  if (skuInfo.value?.sku_code) {
    // sku维度的mall
    return skuInfo.value.mall_stock || []
  }
  return []
})

const changeShowSizeRecommendModal = (value) => { 
  showSizeReommendModal.value = value 
}
const changeRecommendSize = (value) => { recommendSize.value = value }
const closePopover = debounce({
  func: () => {
    Object.values(sizeRefs.value['popoverRef'] || {}).forEach((item) => {
      item?.close?.()
    }) 
  },
  wait: 300,
  options: { leading: true }
})
const editMySize = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-78',
    extraData: {
      goods_id: props.goodsId,
      method: 'self'
    }
  })
  changeShowSizeRecommendModal(true)
}
const showQuickviewModal = (e, isEnter, data, auto) => {
  if (typeof window === 'undefined') return
  const { from, isMobile, isHitComplianceMode } = props.config
  const { hideSizeGroupeSize, activityFrom } = data || {}
  if (isMobile && !auto) return
  if (isMobile) {
    agenSizeHover()
    agenSizeHover(e, isEnter)
  }
  if(activityFrom !== 'sizegroup') {
    daEventCenter.triggerNotice({
      daId: '1-8-6-35'
    })
  }
  if (from === 'detail') {
    const mainGoodsId = props.baseInfo?.goods_id || ''
    emits('openQuickView', {
      goodsId: data.goods_id,
      mainGoodsId,
      config: {
        isHiddenPlusSize: true,
        hideSizeGroupeSize,
        showEstimatedPrice: !isHitComplianceMode,
      },
      callback: {
        handleOpenLogin: (addToWish) => {
          emits('showLogin', addToWish)
        },
        handleAddToCartDone(res) {
          window.cart_module?.recart?.(res)
          emits('autoGetCouponAtAddBag', { needToast: res?.code == 0, isMainGoods: mainGoodsId == data.goods_id } )
        }
      },
      analysis: {
        pageType: 'ItemList',
        index: 10,
        sa: {
          code: 'detaiMain',
          activity_from: activityFrom || 'more_size'
        },
        ga: {
          eventCategory: '商品详情页'
        },
      }
    })
  }else {
    const { goods_url_name, goods_id, cat_id } = data
    const url = props.constant?.langPath + getGoodsUrl(goods_url_name, goods_id, cat_id)
    if (isMobile) {
      location.href = url
      return
    }
    window.open(url)
  }
}
const openSizeGuideModal = (source, config = {}) => {
  if (source === 'sizeTips') {
    const { isLargeSizeTips } = props.tipsCombo || {}
    daEventCenter.triggerNotice({
      daId: '1-8-6-137',
      extraData: {
        type: isLargeSizeTips ? 1 : 2
      }
    })
  }
  if (source === 'sizeRecommend') {
    changeShowSizeRecommendModal(false)
    openSizeGuideFromRecommend.value = true
  }
  loadSizeGuideModal.value = true
  showSizeGuideModal.value = true

  if (source !== 'pc_size_fit') {
    daEventCenter.triggerNotice({
      daId: '1-8-6-236',
      extraData: {
        from: sizeGuideFromSceneMap[config?.fromScene]
      }
    })
  }
  
  if (config?.fromScene === 'pc_size_fit') {
    sa('send', {
      activity_name: 'click_size_information'
    })
  }
}
const onChangeSelectCountry = (value) => {
  const { isNewSizeLocal, show } = props.config || {}
  if (isNewSizeLocal) {
    daEventCenter.triggerNotice({
      daId: props.config.showOnlyOneLocalSize ? '1-8-6-128' : '1-8-6-129',
      extraData: {
        choose_type: value,
        style_type: props.config.showOnlyOneLocalSize ? 'tab' : 'list'
      }
    })
  }
  emits('onChangeSelectCountry', value, { isNewSizeLocal })
}
// const onChangeFeedBackCountry = (value) => {
//   const { isNewSizeLocal } = props.config || {}
//   if (isNewSizeLocal) emits('onChangeSelectCountry', value, { isNewSizeLocal })
// }
const onChangeUnit = (value) => {
  emits('onChangeUnit', value)
}
const handleClickSizeGroupItem = (item) => {
  emits('handleClickSizeGroupItem', item)
}
const handleUpdateQuickShip = (value) => { emits('handleUpdateQuickShip', value) }
const preCalSizePopLeft = () => {
  if (!props.config?.sizeInfoAtOuter) {
    sizePopoverPlacemen.value.splice(0)
    sizePopoverPlacemen.value.push(agenSizeHover({ target: `.j-product-intro__size-radio-spopover_87_index0` }, true) ? 'top-start' : 'top-end')
    polyAttrSize.value.forEach((item, i) => {
      sizePopoverPlacemen.value.push(agenSizeHover({ target: `.j-product-intro__size-radio-spopover_87_index${i + 1}` }, true) ? 'top-start' : 'top-end')
    })
    sizePopoverPlacemen.value.push(agenSizeHover({ target: `.j-product-intro__size-radio-spopover_87_index999` }, true) ? 'top-start' : 'top-end')
  }
}
const setFoldSizes = () => {
  if (props.config.openFoldSizes) {
    foldBtn.value = []
    let getTop = dom => dom.getBoundingClientRect().top
    const containerDoms = sizeRefs.value['product-intro__size-choose'] || {}
    if (!skcSaleAttr.value.length) return
    Object.values(containerDoms).forEach(item => item.style.height = 'auto')
    for(let i in skcSaleAttr.value) {
      const containerDom = containerDoms?.[i]
      const sizeItemDoms = containerDom?.children
      if (!sizeItemDoms?.length) continue
      const containerDomTop = getTop(containerDom)
      const sizeItemTops = []
      for (let sizeItem of sizeItemDoms) {
        let temp = getTop(sizeItem)
        if (!sizeItemTops.includes(temp)) {
          sizeItemTops.push(temp)
        }
      }
      if (sizeItemTops.length < 5) continue
      if (props.saleAttrOrderMap) {
        sizeItemTops.sort((a, b) => a - b)
      }
      containerDom.style.height = `${sizeItemTops[4] - containerDomTop}px`
      foldBtn.value[i] = true
      foldIndex.value = +i
      daEventCenter.triggerNotice({
        daId: '1-8-6-169'
      })
      break
    }
  }
}
const handleOpenFold = () => {
  foldBtn.value = []
  foldIndex.value = -1
  Object.values(sizeRefs.value['product-intro__size-choose'] || {}).forEach(item => item.style.height = 'auto')
  daEventCenter.triggerNotice({
    daId: '1-8-6-170'
  })
}
const initData = () => {
  initSizeInfoIndex()
  preCalSizePopLeft()
  bubbleInfo.value = getBubbleInfo(skcSaleAttr.value, props.sizeInfoDes, props.tackInfoDes, props.language, props.config?.sizepriority)
}

// 更新推荐尺码信息
const onRecommendedSizeChange = (data) => {
  // 对外暴露onRecommendedSizeChange方法，用于将推荐尺码信息传递给父组件
  emits('onRecommendedSizeChange', data)
}

onMounted(() => {
  initData()
  setFoldSizes()
  // 监听error Tip动画结束
  Object.values(sizeRefs.value['product-intro__size-choose'] || {}).forEach((item) => {
    item?.addEventListener?.('webkitAnimationEnd', () => { notSelectedTipsAnim.value = false }, false)
  })
  //TODO 初始化本地尺码
  const sizeUnit = localStorage.getItem('selectedUnit')
  onChangeUnit(+(sizeUnit || props.sizeInfoDes.sizeUnit || 0))
  // currentSelectedCountry.value = props.country || ''
  // 库存紧张埋点
  if (almostSoldOut.value) {
    daEventCenter.triggerNotice({
      daId: '1-8-6-29'
    })
  }
  // fix: 商详才注册事件提供给其他模块调用，不然会同时触发加车跟商详的size Guide弹窗
  if (props.config.from === 'detail') {
    eventBus.on('sizeGuideModalShow', openSizeGuideModal)
    window.addEventListener('scroll', closePopover)
  }
})

onUnmounted(() => {
  if (props.config.from === 'detail') {
    eventBus.off('sizeGuideModalShow', openSizeGuideModal)
    window.removeEventListener('scroll', closePopover)
  }
})
watch(() => props.saleAttrOrderMap, (newVal, oldVal) => {
  if (typeof window === 'undefined') return
  const diff = JSON.stringify(newVal || {}) !== JSON.stringify(oldVal || {})
  if (diff) {
    nextTick(() => {
      setFoldSizes()
      preCalSizePopLeft()
    })
  }
})
watch(() => skcSaleAttr.value, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    emits('onUpdateOldData', {
      polyAttrSize: polyAttrSize.value,
      skcName: skcName.value,
      skcSaleAttr: skcSaleAttr.value,
      skuList: skuList.value,
      tackInfoDes: props.tackInfoDes,
    })
    if (typeof window !== undefined) {
      if (props.relatedLocalSize?.relateGood?.goods_id) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-34'
        })
      }
      if (props.config.showHightSize && polyAttrSize.value?.some?.(item => !!heightSizeObject.value?.[item?.attr_value_name])) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-140'
        })
      }
    }
  }
}, {
  immediate: true,
})
watch(() => goodsId.value, (newVal, oldVal) => {
  if (newVal) {
    initData()
    nextTick(() => {
      if (oldVal && newVal != oldVal && props.config.openAutoCheckMall && !chooseCheck(-1, '', '', props.mallCode)) {
        const hasStockMall = props.baseInfo?.mall_info?.find((_) => !!chooseCheck(-1, '', '', _?.mall_code))
        const mallCode = hasStockMall?.mall_code || ''
        if (mallCode === '') return
        mallClick({ 
          mallCode,
          mallStock: chooseCheck(-1, '', '', mallCode)
        })
      }
      setFoldSizes()
    })
  }
})
watch(() => props.country, (value) => { 
  currentSelectedCountry.value = value 
}, {
  immediate: true,
})
watch(() => props.config.checkNotSelectedTips, (val) => {
  if (val) {
    updateNotSelectedTips()
    notSelectedTipsAnim.value = true
    Object.keys(sizeRefs.value['errorTipRef'] || {}).forEach((key) => {
      const errorTip = sizeRefs.value['errorTipRef'][key]
      nextTick(() => {
        notSelectedTips.value?.includes?.(key) ? errorTip?.focus?.() : errorTip?.blur?.()
      })
    })
    return
  }
  updateNotSelectedTips(true)
})
watch(() => showSizeGuideModal.value, (value) => {
  emits('openSizeGuide', value)
  // sizeRecommend => sizeGuide => sizeRecommend
  if (!value && openSizeGuideFromRecommend.value) {
    changeShowSizeRecommendModal(true)
    openSizeGuideFromRecommend.value = false
  }
})
watch(() => localSizeComputed.value.hasLocalSize, (value) => { emits('onChangeHasSize', value) }, { immediate: true })
watch(() => props.externalSizeInfoIndex, (value) => { 
  if (value && Object.keys(value || {}).length > 0 && JSON.stringify(value) != JSON.stringify(sizeInfoIndex.value)) {
    nextTick(() => initSizeInfoIndex('needSetSizeIndex', value)) 
  }
})
watch(() => props.externalSkuCode, (value) => { 
  if (value !== skuInfo.value?.sku_code) {
    nextTick(() => initSizeInfoIndex('needSetSkuInfo', value)) 
  }
}, {
  immediate: true,
})
watch(() => props.skuMapCompose.skuAllInfo, (value) => {
  const skuCode = skuInfo.value?.sku_code
  if (value?.[skuCode]) {
    sizeEmit()
  }
})
watch(() => tipsInfo.value?.sizeText, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    const { isLargeSizeTips } = tipsInfo.value || {}
    daEventCenter.triggerNotice({
      daId: '1-8-6-138',
      extraData: {
        type: isLargeSizeTips ? 1 : 2,
      }
    })
  }
}, {
  immediate: true,
})
provide('sizeRefs', sizeRefs)
provide('setSizeRefs', setSizeRefs)
provide('config', props.config)
provide('language', props.language)
provide('currentTabLocalIsActive', currentTabLocalIsActive)
provide('commonFn', {
  onChangeSelectCountry,
  chooseCheck,
  handleChoose,
  handleChooseTouch,
  showQuickviewModal,
  openSizeGuideModal,
})
provide('analysisData', analysisData)
</script>

<template>
  <SizeGroup 
    v-if="sizeGroupPosition === 'mid' && tipsReady"
    :from="config.from"
    :goods-id="goodsId"
    :language="language"
    :constant="constant"
    :ccc-attr-groups="cccAttrGroups"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  /> 
  <SizeSelectList
    :sale-attr-order-map="saleAttrOrderMap"
    :hot-label-map="hotLabelMap"
    :hide-disable-attr-id="hideDisableAttrId"
    :skc-sale-attr="skcSaleAttr"
    :related-local-size="relatedLocalSize"
    :size-info-index="sizeInfoIndex"
    :not-selected-tips-anim="notSelectedTipsAnim"
    :local-size-computed="localSizeComputed"
    :height-size-object="heightSizeObject"
    :size-popover-placemen="sizePopoverPlacemen"
    :bubble-info-computed="bubbleInfoComputed"
    :current-selected-country="currentSelectedCountry"
    :not-selected-tips="notSelectedTips"
    :fold-btn="foldBtn"
    :fold-index="foldIndex"
    :is-all-sold-out="isAllSoldOut"
    :country="country"
    :is-new-size-local="config.isNewSizeLocal"
    :size-group-position="sizeGroupPosition"
    :hide-one-size="hideOneSize"
    :tips-ready="tipsReady"
    :constant="constant"
    :show-size-group-after-size="showSizeGroupAfterSize"
    :only-related-goods="onlyRelatedGoods"
    :handle-open-fold="handleOpenFold"
  />
  <SizeGroup
    v-if="showSizeGroupAfterSizeUndertake && tipsReady"
    :from="config.from"
    :goods-id="goodsId"
    :language="language"
    :only-related-goods="onlyRelatedGoods"
    :constant="constant"
    :after-size-scene="true"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  /> 
  <SizeGroup 
    v-if="sizeGroupPosition === 'bottom' && tipsReady"
    :from="config.from"
    :goods-id="goodsId"
    :language="language"
    :ccc-attr-groups="cccAttrGroups"
    :constant="constant"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  /> 
  <SizeGroup 
    v-if="sizeGroupPosition === 'fold' && tipsReady"
    :from="config.from"
    :goods-id="goodsId"
    :language="language"
    :only-related-goods="onlyRelatedGoods"
    :constant="constant"
    :is-paid-user="sizeModalComb.productIntroData && sizeModalComb.productIntroData.isPaidUser"
    :find-perfect-fit-scene="true"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  /> 
  <OtherContentComp 
    :find-similar-bind="findSimilarBind"
    :size-info-index="sizeInfoIndex"
    :bubble-info-computed="bubbleInfoComputed"
    :tips-info="tipsInfo"
    :size-recommend-data="sizeRecommendData"
    :edit-my-size="editMySize"
    :recommend-my-size-text="recommendMySizeText"
    :goods-id="goodsId"
    :hide-one-size="hideOneSize"
    :current-mall="currentMall"
    :real-time-ready="realTimeReady"
    :tips-ready="tipsReady"
  />
  <ProductIntroQuickShip 
    v-if="showQuickShip && tipsReady"
    :from="config.from"
    :mall-code="mallCode"
    :sku-info="skuInfo"
    :sku-list="skuList"
    :skc-sale-attr="skcSaleAttr"
    :local-size-map="localSizeComputed.localSizeMap"
    :language="language"
    :calc-size="calcSize"
    :is-new-size-local="config.isNewSizeLocal"
    :delivery-floor-quickship-title="deliveryFloorQuickshipTitle"
    :is-jp-quick-ship="isJpQuickShip"
    :hidden-partial-quick-ship="config.hiddenPartialQuickShip"
    @update-quick-ship="handleUpdateQuickShip"
  />
  <!-- <ProductIntroSizeFeedback
    v-if="config.showSizeFeedBack"
    :language="language"
    :country="currentSelectedCountry"
    :current-size-list="polyAttrSize"
    :feedback-basic-sizes="feedbackBasicSizes"
    :is-new-size-local="config.isNewSizeLocal"
    :defaultlocalsize="config.defaultlocalsize"
    :is-rw="constant.isRw"
    :product-intro-data="sizeModalComb.productIntroData"
    @change-country="onChangeFeedBackCountry"
  /> -->
  <SizeFeedBackEnter
    v-if="config.showSizeFeedBack"
    :lang-map="language"
    :config="config"
    :goods-id="goodsId"
    :mall-code="mallCode"
    :country="currentSelectedCountry"
    :size-unit="sizeUnit"
    :product-intro-data="sizeModalComb.productIntroData"
  />
  <ProductIntroMall 
    v-if="config.showMallSite"
    :detail="baseInfo"
    :language="language"
    :mall-code="mallCode"
    :mall-stocks="mallStocks"
    :site-uid="constant.siteUid"
    :from="config.from"
    :is-on-sale="!isAllSoldOut"
    :choose-check="chooseCheck"
    :config="config"
    @mall-click="mallClick"
  />
  <ClientOnly>
    <ProductIntroQuickShip 
      v-if="showQuickShipStatus"
      :from="config.from"
      :mall-code="mallCode"
      :sku-info="skuInfo"
      :sku-list="skuList"
      :skc-sale-attr="skcSaleAttr"
      :local-size-map="localSizeComputed.localSizeMap"
      :language="language"
      :calc-size="calcSize"
      :is-jp-quick-ship="isJpQuickShip"
      :is-new-size-local="config.isNewSizeLocal"
      @update-quick-ship="handleUpdateQuickShip"
    />
  </ClientOnly>
  <ClientOnly>
    <ProductSizeGuid 
      v-if="loadSizeGuideModal"
      v-model:value="showSizeGuideModal"
      :current-unit="currentUnit"
      :language="language"
      :is-rw="constant.isRw"
      :tsp-show-buy-size-table="tspShowBuySizeTable"
      :product-intro-data="sizeModalComb.productIntroData"
      :sale-attr-list="sizeModalComb.saleAttrList"
      :country="currentSelectedCountry"
      :has-local-size="localSizeComputed.hasLocalSize"
      :poly-attr-size="polyAttrSize"
      :is-new-size-local="config.isNewSizeLocal"
      :size-priority="config?.sizepriority"
      :size-guide-fit="config?.sizeguidefit"
      :recommended-size-data="recommendedSizeData"
      :is-show-modal-info="isShowModalInfo"
      @change-select-country="onChangeSelectCountry"
      @update:current-unit="onChangeUnit"
    />
    <SizeRecommend 
      v-if="config.from === 'detail'"
      :show-size-reommend-modal="showSizeReommendModal"
      :current-unit="currentUnit"
      :goods-id="goodsId"
      :user-select-size="userSelectSize"
      :poly-attr-size="polyAttrSize"
      :size-modal-comb="sizeModalComb"
      :size-recommend-data="sizeRecommendData"
      :language="language"
      :size-info-index="sizeInfoIndex"
      :choose-check="chooseCheck"
      :handle-choose="handleChoose"
      :is-rw="constant.isRw"
      @open-size-guide-modal="openSizeGuideModal"
      @change-show-size-recommend-modal="changeShowSizeRecommendModal"
      @change-recommend-size="changeRecommendSize"
      @recommended-size-change="onRecommendedSizeChange"
    />
  </ClientOnly>
</template>

<style lang="less">
.product-intro {
  &__size-title {
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    span {
      font-weight: 400;
    }
  }
  &__size-choose {
    overflow: hidden;
    .sui-popover__content {
      min-width: 320px;
    }
  }
  &__size-radio {
    position: relative;
    min-width: 66px;
    min-height: 32px;
    line-height: 16px;
    padding: 8px 4px;
    border: 1px solid #e5e5e5;
    border-radius: 48px;
    font-size: 0;
    display: inline-block;
    text-align: center;
    max-width: 100%;
    cursor: pointer;
    margin: 0 10px 10px 0;
    .inner {
      font-size: 14px;
      display: inline-block;

      span {
        display: inline-block;
      }
    }
    &:hover {
      border: 1px solid #222;
      /* rw:begin*/
      border: 1px solid transparent;
      &::before {
        /* rw:begin*/
        position: absolute;
        .left(0);
        top: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border: 2px solid #ff696e;
        border-radius: 16px;
        background: transparent;
        pointer-events: none;
      }
    }

    &.relate-good:hover {
      /* sh:begin*/
      border-color: #e5e5e5;
      color: #003569;
    }

    &.relate-good span {
      margin-left: 3px;
    }
    &.relate-good span:first-child {
      margin-left: 0;
    }
    &_soldout {
      color: #999;
      border: 1px dashed #bfbfbf;
      // cursor: not-allowed;
      &:hover {
        border: 1px dashed #bfbfbf;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }
    &_disabled {
      color: #999;
      border: 1px dashed #bfbfbf;
      background: #f6f6f6;
      cursor: not-allowed;
      &:hover {
        border: 1px dashed #bfbfbf;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }
    &_active {
      border: 1px solid transparent;
      color: @sui_color_main;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 100%;
        height: 100%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border: 2px solid #222;
        box-sizing: content-box;
        border-radius: 16px;
        background: transparent;
        border: 2px solid @sui_color_main;
      }
      &:hover {
        border: 1px solid transparent;
      }
    }
    &_soldout {
      color: #999;
    }
  }
  &__size-radio-inner {
    max-width: 100%;
    font-size: 14px;
    padding: 0 12px;
    overflow: hidden;
    .icon-play1 {
      display: inline-block;
      transform-origin: center;
      transform: rotate(0deg) /* rtl:rotate(180deg) */;
    }
    .to-right {
      transform: rotate(90deg) /* rtl:ignore */;
    }
  }
}
</style>
