<template>
  <div 
    class="goods-color j-expose__product-intro__color"
  >
    <div class="goods-color__title j-expose__product-intro__color-title">
      <div
        class="color-block"
        tabindex="0"
        :aria-label="currColorInfo.name + ' ' + currColorInfo.val"
      >
        <span class="title">{{ currColorInfo.name }}</span>
        <span class="sub-title">{{ currColorInfo.val }}</span>
      </div>
      <div class="goods-color__title-link-box">
        <span
          v-if="showFindMyShadeEntry"
          v-expose="{ id: '1-8-4-5' }"
          class="color-link"
          da-event-click="1-8-4-4"
          @click="handleFindMyShadeClick"
        >
          {{ language.SHEIN_KEY_PC_19812 }}
        </span>
        <span
          v-if="showLargeImage"
          class="color-link"
          da-event-click="1-8-6-124"
          @click="handleLargeImageClick(true)"
        >
          {{ language.SHEIN_KEY_PC_24404 }}
        </span>
      </div>
    </div>
    <div
      ref="radioContainer"
      class="goods-color__radio-container"
      :style="{
        height: radioContainerHeight,
      }"
    >
      <div>
        <ColorRadio
          v-for="(color, index) in colorList"
          :key="color.goods_id"
          :is-detail="isDetail"
          :cdn-url="cdnUrl"
          :color="color"
          :color-type="colorType"
          :img-field="imgField"
          :is-support-crop-image="isSupportCropImage"
          :fixed-ratio="fixedRatio"
          :color-config="colorConfig"
          :language="language"
          :is-show-attr-new-icon="isShowAttrNewIcon"
          :show-evolushein="showEvolushein"
          @radio-click="color => handleSwitchColor(color, false, index)"
          @radio-popver-open="handlePopverOpen"
        />
      </div>
    <div
        v-if="showMoreEl && !showMoreColor"
        class="goods-color__mask"
      ></div>
    </div>
    <div
      v-if="showMoreEl"
      class="goods-color__more"
    >
      <div
        class="goods-color__color-more"
        :aria-label="
          language[!showMoreColor ? 'SHEIN_KEY_PC_20458' : 'SHEIN_KEY_PC_20457']
        "
        tabindex="0"
        @click="handleShowMoreColorClick"
      >
        <span>
          {{
            language[
              !showMoreColor ? 'SHEIN_KEY_PC_20458' : 'SHEIN_KEY_PC_20457'
            ]
          }}
          <Icon
            v-if="showMoreColor"
            name="sui_icon_more_up_18px"
            size="16px"
          />
          <Icon
            v-else
            name="sui_icon_more_down_18px"
            size="16px"
          />
        </span>
      </div>
    </div>
    <ClientOnly>
      <ColorDesc
        :goods-desc="colorGoodsDesc"
        :language="language"
      />
      <LargeImageColorDrawer
        v-if="showLargeImage && renderLargeImageDrawer"
        :visible="showLargeImgDrawer"
        :color-config="colorConfig"
        :color-title="currColorInfo.name + currColorInfo.val"
        :list="colorList"
        :color-type-list="colorTypeList"
        :language="language"
        :load-conf="loadConf"
        :fixed-ratio="fixedRatio"
        :is-support-crop-image="isSupportCropImage"
        :is-show-attr-new-icon="isShowAttrNewIcon"
        :show-evolushein="showEvolushein"
        @switch-color="(color, isLarge = true, index) => handleSwitchColor(color, true, index)"
        @close="handleLargeImageClick(false)"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { ClientOnly } from '@sheinfe/vue-client-only'
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  ref,
  toRefs,
  onMounted,
  nextTick,
  watch,
} from 'vue'

import ColorRadio from './components/ColorRadio/index'
import ColorDesc from './components/ColorDesc/index'
import useColorBox from './composition/ColorBox'
import { Icon } from '@shein-aidc/icon-vue3'

const LargeImageColorDrawer = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "LargeImageColorDrawer" */ './components/LargeImageColorDrawer/index.vue'
  )
)

import { daEventCenter } from 'public/src/services/eventCenter/index'

defineComponent({
  name: 'ColorBox',
  components: {
    ClientOnly,
    ColorRadio,
    ColorDesc,
    Icon,
    LargeImageColorDrawer,
  },
})

/**
 * @param {Object} colorConfig 配置类相关信息
 */
const props = defineProps({
  cdnUrl: {
    type: String,
    default: '',
  },
  isSupportCropImage: {
    type: Boolean,
    default: false,
  },
  fixedRatio: {
    type: String,
    default: '3-4',
  },
  loadConf: {
    type: Object,
    default: () => ({
      size: '1',
      num: 1,
    }),
  },
  colorConfig: {
    type: Object,
    default: () => ({
      colorType: 'radio',
      imgField: 'color_image',
      showAttrImgPopover: false,
    }),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  mainSaleAttribute: {
    type: Object,
    default: () => ({}),
  },
  colorList: {
    type: Array,
    default: () => [],
  },
  colorGoodsDesc: {
    type: Boolean,
    default: false,
  },
  realTimeBffReady: {
    type: Boolean,
    default: false,
  },
  showLargeImage: {
    type: Boolean,
    default: false,
  },
  beautyFindationAbt: {
    type: Object,
    default: () => ({}),
  },
  showFindMyShadeEntry: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: true,
  },
  isShowAttrNewIcon: {
    type: Boolean,
    default: false,
  },
  showEvolushein: {
    type: Boolean,
    default: false,
  },
  currentColorInfo: {
    type: Object,
    default: () => ({}),
  }
})

const emits = defineEmits(['switchColor', 'close'])

const {
  isDetail,
  colorGoodsDesc,
  showLargeImage,
  showFindMyShadeEntry,
  colorList,
  colorConfig,
  mainSaleAttribute,
  isShowAttrNewIcon,
  showEvolushein,
} = toRefs(props)
// 无需响应式变量
// eslint-disable-next-line vue/no-setup-props-destructure
const { language, loadConf, cdnUrl, fixedRatio, isSupportCropImage } = props

useColorBox({
  colorList,
  loadConf,
})

// colorType: radio | block | text
const colorType = computed(() => colorConfig.value.colorType)

const imgField = computed(() => colorConfig.value.imgField)

const currmainSaleAttribute = computed(() => {
  return mainSaleAttribute.value?.[0] || {}
})

const currColorInfo = computed(() => {
  const isShowThickening = props.currentColorInfo.isShowThickening

  return {
    name: currmainSaleAttribute.value.attr_name,
    val: colorType.value !== 'text' ? `: ${currmainSaleAttribute.value.attr_value}${isShowThickening ? ' ' + props.language.SHEIN_KEY_PC_33144 : ''}` : '',
  }
})

// 大图模式
const showLargeImgDrawer = ref(false)
const renderLargeImageDrawer = ref(false)
onMounted(() => {
  setTimeout(() => {
    if (showLargeImage.value) {
      renderLargeImageDrawer.value = true // mounted时预加载
    }
  }, 500)
})
const handleLargeImageClick = (flag = true) => {
  if (!renderLargeImageDrawer.value) return
  if (renderLargeImageDrawer.value) {
    // 后续渲染了 直接显示
    showLargeImgDrawer.value = flag
    return
  }
  renderLargeImageDrawer.value = true
  showLargeImgDrawer.value = flag
}

// findMyShade
const handleFindMyShadeClick = () => {
  let findShadeRuleId = ''
  import('public/src/pages/detail/main/components/find_shade/index.js').then(res => {
    const FindMyShadeInstance = res.default
    const beautyFindationAbt = props.beautyFindationAbt
    if (/^is_pde/.test(beautyFindationAbt.param)) {
      const matchResult = beautyFindationAbt.param.match(/rule_id=(.*)/)
      findShadeRuleId = (matchResult && matchResult[1]) || ''
    }
    const mainAttribute = currmainSaleAttribute.value.attr_value_id
      ? currmainSaleAttribute.value.attr_id +
        '_' +
        currmainSaleAttribute.value.attr_value_id
      : ''

    FindMyShadeInstance.show({
      ruleId: findShadeRuleId,
      urlQuery: 'main_attr=' + mainAttribute,
      urlFilter: /&?attr_ids=(\d|_)+/g,
    })
  })
}

// 多色块折叠
const showMoreEl = ref(false)
const radioContainer = ref(null)
const needFoldHeight = computed(() => (colorType.value === 'block' ? 260 : 184))
const radioContainerHeight = ref('auto')

onMounted(() => {
  watch(() => colorList.value, () => {
    radioContainerHeight.value = 'auto'
    nextTick(() => {
      if (radioContainer.value?.offsetHeight >= needFoldHeight.value) {
        showMoreEl.value = true
        if (!showMoreColor.value) {
          radioContainerHeight.value = `${needFoldHeight.value}px`
        }
      } else {
        showMoreEl.value = false
      }
    })
    
    colorTypeList.value = []
    colorList.value?.map(item => {
      let icon_type = ''
      if (item.isEco) {
        icon_type = 'evo'
      } else if (item.isNew && isShowAttrNewIcon.value) {
        icon_type = 'new'
      } else if(item.lowPrice) {
        icon_type = 'low_price'
      } else if (item.isHot) {
        icon_type = 'hot'
      } else if (item.isShowThickening) {
        icon_type = 'thickening'
      }
      // let icon_type = 
      //   item.isEco ? 'evo' :
      //     item.isNew && isShowAttrNewIcon.value ? 'new' :
      //       item.isHot ? 'hot' : ''

      colorTypeList.value.push({
        color: item.goods_id,
        icon_type,
        is_sold_out: item.isSoldoutColor ? '1' : '0'
      })
    })
  }, { immediate: true, deep: true })
  watch(() => props.realTimeBffReady, (val) => {
    if (val) {
      nextTick(() => {
        const { id, data } = extraData.value
        daEventCenter.triggerNotice({
          daId: id,
          extraData: data,
        })
      })
    }
  }, { immediate: true })
})


const showMoreColor = ref(false)
const handleShowMoreColorClick = () => {
  showMoreColor.value = !showMoreColor.value
  if (showMoreColor.value) {
    radioContainerHeight.value = 'auto'
  } else {
    radioContainerHeight.value = `${needFoldHeight.value}px`
  }
}

// 事件
const handleSwitchColor = (color, fromLargeImage = false, index) => {
  if (color.isCurrentGoods) return
  emits('switchColor', color)

  // 选中颜色报埋点
  setTimeout(() => {
    reportClickGoodsColor(color, fromLargeImage, index)
  }, 500)
}

const handlePopverOpen = () => {
  setTimeout(() => {
    reportPopverOpen()
  }, 500)
}

const colorTypeList = ref([])

// 埋点相关
const extraData = computed(() => {
  const { isDetail, loadConf, colorsCompData, showLargeImage } = props
  const otherData = {
    location: isDetail ? 'page' : 'popup',
    image_tp: 'small',
    ...(loadConf?.showPromotionTagInfo
      ? {
        promotion_type: colorsCompData?.promotionTypeId || '-',
        source: loadConf?.source,
      }
      : {}),
  }
  return {
    id: '1-8-6-24',
    data: {
      if_have_switch_image_entry: showLargeImage ? 1 : 0,
      color_list: colorTypeList.value,
      location: isDetail ? 'page' : 'popup',
      image_tp: 'small',
      ...otherData
    }
  }
})

const reportClickGoodsColor = (color, fromLargeImage, index) => {
  const { loadConf, promotionTagInfos, isDetail } = props
  const otherData = loadConf?.showPromotionTagInfo
    ? {
      promotion_type: promotionTagInfos[color.goods_id]?.typeId || '-',
      source: loadConf?.source,
    }
    : {}
  let list = colorTypeList.value.filter?.(item => item.color === color.goods_id) || []
  daEventCenter.triggerNotice({
    daId: '1-8-6-4',
    extraData: {
      color_list: list,
      color: color.goods_id,
      condition: Number(color.isSoldoutColor),
      location: fromLargeImage || !isDetail ? 'popup' : 'page',
      image_tp: fromLargeImage ? 'large' : 'small',
      position: index + 1,
      ...otherData,
    },
  })
}



const currentGoodsId = computed(() => {
  const currentSelectedColor = colorList.value.find(item => item.isCurrentGoods)
  return currentSelectedColor?.goods_id
})
const reportPopverOpen = () => {
  const { isDetail } = props
  daEventCenter.triggerNotice({
    daId: '1-8-6-197',
    extraData: {
      goods_id: currentGoodsId.value,
      location: isDetail ? 'page' : 'popup',
      main_attr: 1,
    },
  })
}
</script>

<style lang="less" scoped>
.goods-color {
  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;

    .color-block {
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      .title {
        font-weight: 700;
      }

      .sub-title {
        font-weight: normal;
        .padding-l(9px);
        color: #222;
      }
    }

    &-link-box {
      .color-link {
        display: inline-block;
        font-weight: 400;
        color: #3379cb;
        font-size: 14px;
        margin-left: 16px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: #1860a7;
          text-decoration: none;
        }
      }
    }
  }

  &__radio-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding-top: 10px;
    overflow: hidden;
  }

  &__mask {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 35px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    z-index: @zindex-hack;
  }

  &__color-more {
    padding-top: 15px;
    font-size: 12px;
    color: #1860a7;
    cursor: pointer;
    /* rw:begin*/
    color: #ff9999;
  }
}
</style>
