<template>
  <div class="pricePopover">
    <SPopover
      v-if="showPop"
      ref="popoverRef"
      :placemen="placemen"
      :append-to-body="true"
      trigger="click"
    >
      <template #reference>
        <Icon
          name="sui_icon_info_12px_1"
          size="11px"
          class="pricePopover__icon"
          color="#959595"
        />
      </template>
      <div class="pricePopover__content">
        <p
          v-if="pricePopoverInfo.taxTip"
          class="pricePopover__mainitem"
        >
          {{ pricePopoverInfo.taxTip }}
        </p>
        <p
          v-if="pricePopoverInfo.deSspTip"
          class="pricePopover__subitem"
        >
          {{ pricePopoverInfo.deSspTip }}
        </p>
        <p
          v-if="pricePopoverInfo.sspTip"
          class="pricePopover__subitem"
        >
          {{ pricePopoverInfo.sspTip }}
        </p>
        <p
          v-if="pricePopoverInfo.rrpTip"
          class="pricePopover__subitem"
        >
          {{ pricePopoverInfo.rrpTip }}
        </p>
      </div>
    </SPopover>
  </div>
</template>

<script setup name="PricePopover">
import { computed, watch, ref, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
const props = defineProps({
  pricePopoverInfo: {
    type: Object,
    default: () => ({})
  }
})
const placemen = computed(() => props.pricePopoverInfo.rrpTip || props.pricePopoverInfo.isFloorType ? 'bottom' : 'top')

const showPop = ref(true)
const popoverRef = ref(false)
watch(() => props.pricePopoverInfo.skuCode, () => {
  showPop.value = false
  nextTick(() => showPop.value = true)

})

const closePopover = () => {
  popoverRef.value?.close?.()
}
onMounted(() => {
  window.addEventListener('scroll', closePopover)
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', closePopover)
})
</script>

<style lang="less">
.pricePopover {
  cursor: pointer;
  &__icon {
    transform: translateY(-1px)
  }
  &__content {
    color: #767676;
    font-size: 14px;
  }
  &__mainitem {
    white-space: nowrap;
    font-weight: bold;
  }
  &__subitem {
    width: 236px;
    margin-top: 8px;
  }
}
</style>
