<template>
  <div
    class="product-intro__freeshipping-item full-line no-limit padding-bootom-0"
    tabindex="0"
    :aria-label="freeshippingText"
    da-event-click="1-8-6-13"
    @click="showShippingModal"
  >
    <div class="product-intro__freeshipping-icon shipping">
      <template v-if="largeShipStatus">
        <sui_icon_landship_flat_18px
          v-if="largeShipStatus == 2"
          size="24"
          color="#198055"
        />

        <sui_icon_seaship_flat_18px
          v-else
          size="24"
          color="#198055"
        />
      </template>

      <sui_icon_freeshipping_flat_24px
        v-else-if="useStrongStyleType === 0"
        size="24"
        color="#198055"
      />

      <sui_icon_qucikship_flat_24px
        v-else-if="useStrongStyleType === 1"
        size="24"
        color="#198055"
      />

      <sui_icon_freeshipping_line_24px
        v-else
        size="24"
        color="#198055"
      />
    </div>
    <div>
      <template v-if="hasShippingSummary">
        <template v-if="largeShipStatus">
          <div
            class="product-intro__largeShip-title"
            v-html="largeShipStatusText"
          ></div>
          <div
            class="product-intro__largeShip-time"
            v-html="largeShipTime"
          ></div>
        </template>
        <template v-else>
          <div
            v-if="!shippingStyleV1 && !satisfyQuickshipStrongAbt"
            class="product-intro__freeshipping-title"
          >
            <span>{{ freeshippingText }}</span>
            <Icon
              class="product-intro__freeshipping-more"
              name="sui_icon_doubt_circle_14px_1"
              color="#999"
              size="14px"
            />
          </div>
          <div
            v-if="
              showDescQuickShipTime &&
                !quickShipHighlight &&
                !satisfyQuickshipStrongAbt
            "
            v-expose="{
              id: '1-8-6-115'
            }"
            class="product-intro__quickship-tip no-ml"
            v-html="descQuickShipTime"
          ></div>
          <!-- 包邮门槛 -->
          <div
            v-if="
              shippingSummary.textType === 'free' ||
                shippingSummary.textType === 'threshold' ||
                shippingSummary.textType === 'nofree'
            "
          >
            <div
              :class="{
                'product-intro__freeshipping-desc': !shippingStyleV1,
                'product-intro__freeshipping-title': shippingStyleV1 || satisfyQuickshipStrongAbt,
                'no-mb': shippingStyleV1 || satisfyQuickshipStrongAbt,
                'no-pb': shippingStyleV1 || satisfyQuickshipStrongAbt,
                'product-intro__freeshipping-strong-text': satisfyQuickshipStrongAbt
              }"
            >
              <!-- 优惠券、商品、活动免邮 -->
              <template v-if="isLogisticsShippinFree">
                <span
                  v-html="
                    calcFreeShipping(
                      shippingSummary.list,
                      'detail',
                      shippingStyleV1,
                      isLogisticsShippinFree
                    )
                  "
                ></span>
              </template>
              <!-- 不支持包邮  -->
              <template
                v-else-if="shippingSummary.list.is_support_free_shipping == 0"
              >
                <span
                  v-html="handleNoFreeDom(language.SHEIN_KEY_PC_19523)"
                ></span>
              </template>
              <!-- 免邮 包邮门槛 都在计算里 生产html片段 -->
              <template v-else-if="shippingSummary.list.aging_is_show == 1">
                <span
                  v-html="
                    calcFreeShipping(
                      shippingSummary.list,
                      'detail',
                      shippingStyleV1,
                      isLogisticsShippinFree
                    )
                  "
                ></span>
              </template>
              <!-- shipping as low as {0} -->
              <template
                v-else-if="
                  shippingSummary.list.aging_is_show === 0 &&
                    shippingSummary.list.lowestShippingPrices
                "
              >
                <template v-if="shippingSummary.list.shipping_mold == 1">
                  {{
                    template(
                      shippingSummary.list.starting_price_obj.amountWithSymbol,
                      language.SHEIN_KEY_PC_17728
                    )
                  }}
                </template>
                <!-- shipping as low as {0} -->
                <template v-else>
                  {{
                    template(
                      shippingSummary.list.lowestShippingPrices
                        .amountWithSymbol,
                      language.SHEIN_KEY_PC_17728
                    )
                  }}
                </template>
              </template>

              <template v-if="shippingStyleV1 || satisfyQuickshipStrongAbt">
                <Icon
                  class="product-intro__freeshipping-more"
                  name="sui_icon_doubt_circle_14px_1"
                  color="#999"
                  size="14px"
                />
              </template>
            </div>

            <p
              v-if="quickShipHighlight && !satisfyQuickshipStrongAbt"
              v-expose="{
                id: '1-8-6-115'
              }"
              class="product-intro__quickship-highlight-tip"
              v-html="descQuickShipTime"
            ></p>

            <!-- 时效信息 -->
            <div class="product-intro__freeshipping-desc">
              <template v-if="satisfyQuickshipStrongAbt">
                <p
                  v-if="showQsTimeDom"
                  class="product-intro__freeshipping-time product-intro__freeshipping-time-strong"
                > 
                  <template v-if="quickShipTimeType == '3'">
                    <span v-html="simplifyAgingText"></span>.
                  </template>
                  <template v-else>
                    {{ shipAsLowAsDom }}{{ quickshipStrongOutsideText }} 
                    <span class="green">{{ quickshipAgingText }}.</span> 
                  </template>
                  <span 
                    v-if="holidayTimeLimit?.qsHolidayTime" 
                    class="holiday-green"
                  > {{ holidayTimeLimit?.qsHolidayTime }}</span>
                </p>

                <div
                  v-if="
                    quickShip !== 1 ||
                      (isSupportQuickship.hasPartSupportQuickship &&
                        !isSupportQuickship.hasSelectedSku) ||
                      !isSupportQuickship.isSomeSupportQuickship
                  "
                  class="product-intro__freeshipping-standard-shipstext"
                  :class="{ 'has-top': showQsTimeDom }"
                >
                  <span v-html="simplifyAgingText"></span>
                  <p
                    v-if="showPercentDes && !isSupportQuickship.isSomeSupportQuickship"
                    style="margin-top: 5px"
                  >
                    <span
                      v-expose="dayPercentExpose(shippingSummary.list)"
                      style="line-height: 14px;"
                      @click.stop="
                        showShippingModal($event, 0, shippingSummary.list)
                      "
                      v-html="getDayPercentDes(shippingSummary.list)"
                    ></span>
                    <template v-if="isUs && shippingSummary.list.day_percents && shippingSummary.list.day_percents.length">
                      <s-popover
                        trigger="hover"
                        placemen="bottom"
                      >
                        <!-- CONTENT --> 
                        <ShippingDayPercent
                          :item="shippingSummary.list"
                          :language="language"
                          :large-ship-status="largeShipStatus"
                          :need-scroll="true"
                        />
                        <template #reference>
                          <Icon 
                            name="sui_icon_doubt_16px_1" 
                            size="14px"
                            color="#959595"
                            style="margin: -2px 2px 0"
                          />
                        </template>
                      </s-popover>
                    </template>
                  </p>
                  <span 
                    v-if="holidayTimeLimit?.normalAgingMap?.[shippingSummary.list?.transport_type]" 
                    class="holiday-green"
                  > {{ holidayTimeLimit?.normalAgingMap?.[shippingSummary.list?.transport_type] }}</span>
                </div>
                <p
                  v-if="showNDeliveryTip"
                  class="product-intro__freeshipping-n-delivery"
                >
                  {{ template(quickShipContent, language.SHEIN_KEY_PC_32659 || '{0} : Excludes weekend and holidays') }}
                </p>
              </template>
              <template
                v-else-if="
                  shippingStyleV1 && shippingSummary.list.desc_type == 1
                "
              >
                <p class="product-intro__freeshipping-time">
                  <span
                    v-if="!quickShipHighlight && showLabel"
                    class="gray"
                  >
                    {{ language.SHEIN_KEY_PC_30161 }}
                  </span>
                  <span
                    v-if="
                      shippingSummary.list.aging_is_expose == 1 &&
                        !quickShipHighlight
                    "
                    class="black"
                  >
                    {{ shippingSummary.list.aging_date
                    }}{{ showPercentDes ? ',' : '' }}
                  </span>
                  <span 
                    v-if="holidayTimeLimit.normalAgingMap?.[shippingSummary.list?.transport_type]" 
                    class="holiday-green"
                  > {{ holidayTimeLimit.normalAgingMap?.[shippingSummary.list?.transport_type] }}</span>
                  <span
                    v-if="showPercentDes"
                    v-expose="dayPercentExpose(shippingSummary.list)"
                    @click.stop="
                      showShippingModal($event, 0, shippingSummary.list)
                    "
                    v-html="getDayPercentDes(shippingSummary.list)"
                  ></span>
                </p>
              </template>
              <template v-else>
                <p
                  v-if="
                    shippingSummary.list.aging_is_expose == 1 &&
                      !quickShipHighlight
                  "
                  class="product-intro__freeshipping-time"
                > 
                  <template v-if="getShippingOutsideText(shippingSummary.list, false)">
                    {{ getShippingOutsideText(shippingSummary.list, false) }}
                    <span 
                      v-if="holidayTimeLimit.normalAgingMap?.[shippingSummary.list?.transport_type]" 
                      class="holiday-green"
                    > {{ holidayTimeLimit.normalAgingMap?.[shippingSummary.list?.transport_type] }}</span>
                  </template>
                  <template v-else>
                    <br />
                  </template>
                </p>
                <!--  abt 问题  -->
                <p
                  v-if="showPercentDes"
                  v-expose="dayPercentExpose(shippingSummary.list)"
                  class="product-intro__day-des"
                  @click.stop="
                    showShippingModal($event, 0, shippingSummary.list)
                  "
                >
                  <span v-html="getDayPercentDes(shippingSummary.list)"></span>
                </p>
              </template>
            </div>
            <!--包邮延迟时效提示 -->
            <div
              v-if="isShwoDelayWarn && firstDelayWarnTip?.supple_desc"
              v-expose="{ id: '1-6-1-168' }"
              class="product-intro__free-delay-tip"
              style="margin-left: 0"
              :style="{ 'color': shippingAbtState.showCustomizedtime ? '#222' : '#767676' }"
            >
              <span
                v-if="firstDelayWarnTip?.supple_desc"
                v-html="firstDelayWarnTip?.supple_desc"
              ></span>
            </div>
          </div>
          <!-- 不包邮 -->
          <div
            v-else-if="shippingSummary.textType === 'empty'"
            class="product-intro__freeshipping-desc"
            :class="{ 'product-intro__freeshipping-title': shippingStyleV1 }"
          >
            {{ language.SHEIN_KEY_PC_14599 }}
            <Icon
              class="product-intro__freeshipping-more"
              name="sui_icon_doubt_circle_14px_1"
              color="#999"
              size="14px"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="shipping-normal__loading">
          <div class="item"></div>
          <div class="item"></div>
        </div>
      </template>

      <!-- SHEIN CLUB -->
      <ProductIntroShippingSheinClub v-if="showPaidVipTips" />
    </div>
  </div>
</template>

<script>
import { enterMixins, commonMixins } from './mixins'
import {
  sui_icon_seaship_flat_18px,
  sui_icon_landship_flat_18px,
  sui_icon_qucikship_flat_24px,
  sui_icon_freeshipping_flat_24px,
  sui_icon_freeshipping_line_24px,
  Icon
} from '@shein-aidc/icon-vue3'
import { htmlDecode } from '@shein/common-function'
import { mapState } from 'vuex'
import ShippingDayPercent from 'public/src/pages/components/ShippingDayPercent.vue'

export default {
  name: 'ShippingNormal',
  components: {
    Icon,
    sui_icon_seaship_flat_18px,
    sui_icon_landship_flat_18px,
    sui_icon_qucikship_flat_24px,
    sui_icon_freeshipping_flat_24px,
    sui_icon_freeshipping_line_24px,
    ShippingDayPercent
  },
  mixins: [enterMixins, commonMixins],
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    shippingSummary: {
      type: Object,
      default: () => {}
    },
    holidayTimeLimit: {
      type: Object,
      default: () => {}
    },
    largeShipStatus: {
      type: Number,
      default: 0
    },
    agingExtend: {
      type: Object,
      default: () => {}
    },
    isSupportQuickship: {
      type: Object,
      default: () => {}
    },
    useStrongStyleType: {
      type: Number,
      default: 2
    },
    quickshipAgingText: {
      type: String,
      default: ''
    },
    shippingStyleV1: {
      type: Boolean,
      default: false
    },
    isLogisticsShippinFree: {
      type: Boolean,
      default: false
    },
    quickShipHighlight: {
      type: Boolean,
      default: false
    },
    isUs: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      needExcuteQuickshipStrongStyle: true
    }
  },
  computed: {
    ...mapState([
      'productIntroData',
      'quickShip',
      'lang',
    ]),
    showLabel() {
      return (
        (this.shippingSummary.list.aging_is_expose == 1 &&
          this.shippingSummary.list.aging_date) ||
        this.showPercentDes
      )
    },
    firstDelayWarnTip() {
      const [originTips = {}] =
        this.productIntroData?.shippingDetail?.shippingMethods?.slice?.(0, 1)
      const [tips = {}] = this.delayWarn.slice(0, 1)
      return JSON.stringify(tips) !== '{}' ? tips : originTips
    },
    hasShippingSummary() {
      return JSON.stringify(this.shippingSummary) !== '{}'
    },
    largeShipStatusText() {
      if (
        this.shippingSummary.list.aging_is_show === 0 &&
        this.shippingSummary.list.lowestShippingPrices
      ) {
        if (this.shippingSummary.list.shipping_mold == 1) {
          return this.template(
            this.shippingSummary.list.starting_price_obj.amountWithSymbol,
            this.language.SHEIN_KEY_PC_17728
          )
        } else {
          return this.template(
            this.shippingSummary.list.lowestShippingPrices.amountWithSymbol,
            this.language.SHEIN_KEY_PC_17728
          )
        }
      } else {
        let freeDes = this.language.SHEIN_KEY_PC_28938
        if (
          Number(
            this.shippingSummary.full_amount &&
              this.shippingSummary.full_amount.amount
          ) > 0
        ) {
          // 有门槛免邮
          freeDes = this.formatShippingDesc({
            textType: 'threshold',
            shippingType: this.shippingSummary.type,
            shippingAmountWithSymbol:
              this.shippingSummary.full_amount.amountWithSymbol
          })
        }
        return freeDes
      }
    },
    largeShipTime() {
      const { desc_type, date_type, transport_interval } =
        this.shippingSummary?.list || {}
      const { SHEIN_KEY_PC_24975, SHEIN_KEY_PC_24976 } = this.language
      let expectTime = ''
      if (desc_type == 0) {
        // date_type (1=工作日，0=自然日)
        const customDayUnit = htmlDecode({ text: date_type == 1 ? SHEIN_KEY_PC_24975 : SHEIN_KEY_PC_24976 })
        expectTime = transport_interval ? `${transport_interval} ${customDayUnit}` : ''
      } else if(desc_type == 1) {
        expectTime =  this.formatTime(this.shippingSummary.list, this.lang)
      }
      const time =
        this.agingExtend.time_type === 2
          ? this.agingExtend.aging_date
          : expectTime
      const dayPercentDesc = this.agingExtend.day_percent_desc
        ? `<span><span>${this.agingExtend.day_percent_desc}</span></span>`
        : ''
      const hlHolidayTime =  `<span class="green">${this.holidayTimeLimit?.hlHolidayTime}</span>`
      return `<div class="shipping-head__largeShip">
        <span class="shipping-head__largeShip-way">${this.agingExtend.title}, ${
        this.language.SHEIN_KEY_PC_31835
      }: </span>${time}${dayPercentDesc ? ',' : ''}${dayPercentDesc}
      </div>${hlHolidayTime}`
    },
    shipAsLowAsDom() {
      const { isSomeSupportQuickship } = this.isSupportQuickship
      const { is_support_free_shipping } = this.shippingSummary?.list || {}
      // 不包邮 并且不满足商品免邮、活动免邮、优惠券免邮等
      return isSomeSupportQuickship &&
        is_support_free_shipping == 0 &&
        !this.isLogisticsShippinFree
        ? `${this.getAsLowAsText()},`
        : ''
    },
    shippingLanguage() {
      // 物流到达文案
      return {
        stand_shipping_detail_dilvery: this.language.SHEIN_KEY_PC_30592, // 标准
        expedited_shipping_detail_dilvery: this.language.SHEIN_KEY_PC_30591, // 平邮
        express_shipping_detail_dilvery: this.language.SHEIN_KEY_PC_30640, // 快递
        shop_transit_detail_dilvery: this.language.SHEIN_KEY_PC_30593, // 店配
        express_shoptransit_detail_dilvery: this.language.SHEIN_KEY_PC_30595 // 快递店配
      }
    },
    quickshipStrongOutsideText() {
      const { type } = this.shippingSummary?.list || {}
      const { SHEIN_KEY_PC_30412, SHEIN_KEY_PC_29561 } = this.language
      const { hasPartSupportQuickship, hasSelectedSku } =
        this.isSupportQuickship
      if (hasPartSupportQuickship && !hasSelectedSku) {
        // 特殊情况优先级最高：skc下的skuquickship状态不一样，且未选中skc  拼接Quickship delivery
        return `${SHEIN_KEY_PC_30412} ${SHEIN_KEY_PC_29561} `
      } else if (this.quickShip == 1) {
        return `${
          this.shippingLanguage[type + '_detail_dilvery'] || ''
        } ${SHEIN_KEY_PC_29561} `
      }
      return `${SHEIN_KEY_PC_30412} ${SHEIN_KEY_PC_29561} `
    },
    simplifyAgingText() {
      // 不支持qs的简化物流文案 普通物流时效
      const {
        type,
        desc_type,
        aging_date,
        date_type,
        transport_interval = ''
      } = this.shippingSummary?.list || {}
      const { SHEIN_KEY_PC_29561, SHEIN_KEY_PC_24975, SHEIN_KEY_PC_24976 } =
        this.language
      let expectTime = ''
      // 展示文案类型desc_type返回的是0（运输时间）就要取运输时间天数（transport_interval）
      // 展示文案类型返回的是1（预计送达），就要取运输时效 文案（expect_time）
      if (desc_type == 0) {
        // date_type (1=工作日，0=自然日)
        const customDayUnit = htmlDecode({ text: date_type == 1 ? SHEIN_KEY_PC_24975 : SHEIN_KEY_PC_24976 })
        expectTime = transport_interval ? `${transport_interval} ${customDayUnit}` : ''
      } else if(desc_type == 1) {
        expectTime = this.shippingStyleV1 ? aging_date : this.formatTime(this.shippingSummary.list, this.lang)
      }
      if( this.quickShipTimeType == '3') {
        expectTime = `<span class='green'>${expectTime}</span>`
      }
      return `${
        this.shippingLanguage[type + '_detail_dilvery'] || ''
      } ${SHEIN_KEY_PC_29561} ${expectTime}${
        this.showPercentDes && !this.isSupportQuickship.isSomeSupportQuickship
          ? ','
          : ''
      }`
    },
    showQsTimeDom() {
      const {
        isSomeSupportQuickship,
        hasSelectedSku,
        hasSelectedSkuSurpportQuickship
      } = this.isSupportQuickship
      const LogisticSupport =
        (isSomeSupportQuickship && !hasSelectedSku) ||
        (hasSelectedSku && hasSelectedSkuSurpportQuickship)
      return this.quickshipAgingText && LogisticSupport
    },
    showNDeliveryTip() {
      return this.quickShipContent && this.quickShip == 1 && this.isSupportQuickship.isSomeSupportQuickship
    },
    freeshippingText() {
      if (this.hasShippingSummary) {
        if (
          this.shippingSummary.textType === 'free' ||
          this.shippingSummary.textType === 'threshold'
        )
          return this.language.SHEIN_KEY_PC_15911
        if (
          this.shippingSummary.textType === 'empty' ||
          this.shippingSummary.textType === 'nofree'
        )
          return this.language.SHEIN_KEY_PC_15033
      }
      return ''
    },
    showPercentDes() {
      return (
        this.shippingAbtState.showShippingDayPercent &&
        this.getDayPercentDes(this.shippingSummary.list)
      )
    }
  },
  methods: {}
}
</script>

<style lang="less">
.shipping-normal__loading {
  display: flex;
  flex-direction: column;
  height: 60px;
  .item {
    width: 200px;
    height: 10px;
    margin-bottom: 10px;
    background: #f6f6f6;
  }
}
.product-intro {
  &__cart-addItem {
    &.isNotSwiper {
      color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */
      i {
        color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
  &__largeShip-title {
    color: var(---sui_color_safety, #198055);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }
  &__largeShip-time {
    margin-top: 5px;
    font-size: 12px;
  }
  .holiday-green.holiday-green{
    color: #198055;
    white-space: normal;
  }
}
</style>
