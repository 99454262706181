import { ref, nextTick, computed } from 'vue'

// TODO 状态共用 可能有坑...
const addFixed = ref(false)
const addFixedWidth = ref(0)
const closeAddFixed = ref(true)

const renderAddWidth = computed(() => {
  if (addFixed.value) {
    return {
      width: addFixedWidth.value + 'px',
    }
  } else {
    return {
      width: '100%',
    }
  }
})

const getAddFixed = () => {
  const wrapRect = document
    .querySelector('.product-intro__add-wrap')
    ?.getBoundingClientRect()
  if (!addFixed.value) {
    if (wrapRect.bottom > document.documentElement.offsetHeight) {
      addFixed.value = true
    }
  } else {
    const fixedRect = document
      .querySelector('.product-intro__add-status')
      ?.getBoundingClientRect()
    if (wrapRect.top - fixedRect.top < -10) {
      addFixed.value = false
    }
  }
}

const addEvent = isFixed => {
  isFixed && window.addEventListener('scroll', getAddFixed)
}

const initAddFixed = async isFixed => {
  closeAddFixed.value = !isFixed
  addEvent(isFixed)
  await nextTick()
  addFixedWidth.value = document.querySelector(
    '.product-intro__info'
  ).offsetWidth
}

const destroyedAddFixed = () => {
  !closeAddFixed.value && window.removeEventListener('scroll', getAddFixed)
}

export { renderAddWidth, addFixed, initAddFixed, destroyedAddFixed }
