<template>
  <SDialog 
    v-model:visible="dialogShow"
    :type="'W720'"
    :show-close="true"
    :append-to-body="true"
    :observe-body-height="false"
    @closed="onClose"
  >
    <div v-if="status === 'ready'">
      <div class="size-feedback__body">
        <div class="size-feedback-title">
          {{ title }}
        </div>
        <div class="tips-band">
          {{ langMap.SHEIN_KEY_PC_22260 }}
        </div>
        <div class="local-bar">
          <div 
            v-if="heightRangeSizeList.length > 0"
            class="local-bar__container"
          >
            {{ langMap.SHEIN_KEY_PC_33899 || 'Regular Size' }}
          </div>
          <ul class="size-feedback__options">
            <li
              v-for="(size, index) in feedBackSizeList"
              :key="index"
              :class="{ active: size.key === selectedSize.key }"
              @click.stop="selectSize(size)"
            >
              <div class="option-text">
                {{ size.text }}
              </div>
            </li>
          </ul>
        </div>
        <div 
          v-if="heightRangeSizeList.length > 0"
          class="local-bar"
        >
          <div class="local-bar__container">
            {{ langMap.SHEIN_KEY_PC_33897 || 'Petite/Tall Height Range' }}
          </div>
          <ul class="size-feedback__options">
            <li
              v-for="(size, index) in heightRangeSizeList"
              :key="index"
              :class="{ active: size.key === selectedSize.key }"
              @click.stop="selectSize(size)"
            >
              <div class="option-text">
                {{ size.text }}
              </div>
            </li>
          </ul>
        </div>
        <div class="size-feedback__footer">
          <SButton
            :type="['primary']"
            @click="handleSubmit"
          >
            <!-- SUBMIT -->
            {{ langMap.SHEIN_KEY_PC_22300 }}
          </SButton>
        </div>
      </div>
    </div>
    <!-- 提交成功面板 -->
    <div
      v-if="status === 'success'"
      class="size-feedback__success-box"
    >
      <Icon
        class="icon"
        name="sui_icon_success_96px"
        size="96px"
      />
      <!-- Submit successful! -->
      <h2>{{ langMap.SHEIN_KEY_PC_22263 }}</h2>
      <!-- Thank you for your feedback. -->
      <h3 v-show="selectedSize.type === 1">
        {{ langMap.SHEIN_KEY_PC_22264 }}
      </h3>
      <!-- We also have found similar items for you in size -->
      <p v-show="selectedSize.type === 0">
        {{ similarRecommendText }}
      </p>
      <SButton
        v-show="selectedSize.type === 0"
        :type="['default', 'H44PX']"
        @click="handleShowSimilar"
      >
        <!-- SUBMIT -->
        {{ langMap.SHEIN_KEY_PC_22293 }}
      </SButton>
    </div>
  </SDialog>
</template>
<script>
export default {
  name: 'SizeFeedBack'
}
</script>
<script setup>
import { ref, nextTick, computed, defineExpose, defineEmits, watch } from 'vue'
import { template } from '@shein/common-function'
import { SMessage } from '@shein-aidc/sui-message'
import { Icon } from '@shein-aidc/icon-vue3'
import schttp from 'public/src/services/schttp'
const props = defineProps({
  goodsId: {
    type: String,
    default: ''
  },
  langMap: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({})
  },
  sizeUnit: {
    type: String,
    default: '',
  },
  handleOpenFindSimilar: {
    type: Function,
    default: () => {}
  },
})
const emits = defineEmits( ['handle-open-find-similar'])
const dialogShow = ref(false)
// ready: 待提交 | success: 提交成功
const status = ref('ready')
const showSimilarFlag = ref(false)
const selectedSize = ref({
  key: '',
  localSize: '',
  text: '',
  type: -1,
})
const countrySizeDataList = ref([])
const selectCountry = ref('')
const curLocalSizeData = computed(() => countrySizeDataList.value?.find(item => item.countryCode === selectCountry.value))
const hasLocalSizeList = computed(() => !!(countrySizeDataList.value?.length))
const feedBackSizeList = computed(() => {
  // 默认拿第一条的数据
  const { sizeLocalSizeDataList } = curLocalSizeData.value || {}
  const hasLocalSize = !!sizeLocalSizeDataList?.length
  const localSizeData = sizeLocalSizeDataList || countrySizeDataList.value?.[0]?.sizeLocalSizeDataList || []
  const optionsMap = localSizeData.map((item, index) => {
    const { size, localSize } = item
    const text = (hasLocalSize && localSize) ? `${localSize} (${size})` : size
    if (index === 0) {
      return {
        text: template(text || '', props.langMap.SHEIN_KEY_PC_22555 || ''),
        key: 'lt',
        type: 1,
      }
    }
    if (index === localSizeData?.length - 1) {
      return {
        text: template(text || '', props.langMap.SHEIN_KEY_PC_22556 || ''),
        key: 'gt',
        type: 1,
      }
    }
    return {
      text,
      key: size,
      localSize,
      type: 0,
    }
  })
  return optionsMap
})

const heightRangeSizeList = computed(() => {
  const { pettieOrTallList } = curLocalSizeData.value || {}
  const list = pettieOrTallList || countrySizeDataList.value?.[0]?.pettieOrTallList || []
  return list.map(item => {
    const value = props.sizeUnit === 'inch' ? item.inchTall : item.tall
    return {
      text: value,
      key: value,
      type: 1,
    }
  })
})

const title = computed(() => {
  return status.value === 'ready' ? props.langMap?.SHEIN_KEY_PC_22259 : ''
})
const similarRecommendText = computed(() => {
  const { key, type, text } = selectedSize.value || {}
  if (!key || type === 1 || !text) {
    return ''
  }
  return template(text, props.langMap?.SHEIN_KEY_PC_22438 || '')
})
const onClose = () => {
  if (status.value === 'success') {
    nextTick(() => {
      status.value = 'ready'
      selectedSize.value = {
        key: '',
        localSize: '',
        text: '',
        type: -1,
      }
    })
  }
  if(status.value === 'ready') {
    daEventCenter.triggerNotice({
      daId: '1-8-4-16'
    })
    return
  }
  if (!showSimilarFlag.value) {
    daEventCenter.triggerNotice({
      daId: '1-8-4-17'
    })
    return
  }
}
const updateList = async ({ userSwitchLocalCountry = '' }) => {
  const data = await schttp({
    url: '/product/get_feedback_size_info',
    method: 'get',
    params: {
      goods_id: props.goodsId || '',
      mall_code: props.mallCode || '',
      userSwitchLocalCountry,
    },
    useBffApi: true,
  })
  countrySizeDataList.value = data?.info?.countrySizeDataList || []
}
const show = async (params) => {
  showSimilarFlag.value = false
  await updateList(params)
  if(params?.userSwitchLocalCountry) {
    selectCountry.value = countrySizeDataList.value?.find(item => item.isDefaultDisplay === '1')?.countryCode || ''
  }else {
    selectCountry.value = ''
  }
  dialogShow.value = true
}
const getReportSizeValue = (item) => {
  const { key, localSize } = item
  if (!key) {
    return ''
  } else if (key === 'lt') {
    return '1'
  } else if (key === 'gt') {
    return '2'
  }
  return `${key}${localSize ? `(${localSize})` : ''}`
}
const selectSize = (size) => {
  selectedSize.value = size
  daEventCenter.triggerNotice({
    daId: '1-8-4-13',
    extraData: {
      goods_id: props.goodsId,
      size: getReportSizeValue(size),
    }
  })
}
const handleSubmit = () => {
  const { key } = selectedSize.value
  if (!key) {
    SMessage({
      message: props.langMap.SHEIN_KEY_PC_33898 || 'Please choose at least 1 option',
      type: 'error',
    })
    return
  }
  status.value = 'success'
  // 上报埋点
  daEventCenter.triggerNotice({
    daId: '1-8-4-14',
    extraData: {
      goods_id: props.goodsId,
      location: hasLocalSizeList.value ? selectCountry.value : 'Local',
      size: getReportSizeValue(selectedSize.value),
    },
  })
}
const handleShowSimilar = () => {
  const { key } = selectedSize.value
  dialogShow.value = false
  showSimilarFlag.value = true
  // 上报埋点
  daEventCenter.triggerNotice({
    daId: '1-8-4-15',
    extraData: {
      goods_id: props.goodsId,
      size: key
    }
  })
  emits('handle-open-find-similar', selectedSize.value)
}
watch(() => props.goodsId, () => {
  // 重置数据
  countrySizeDataList.value = []
})
defineExpose({
  show
})
</script>
  
<style lang="less" scoped>
.size-feedback {
  &__body {
    position: relative;
    overflow: visible;
    .size-feedback-title{
      height: 28px;
      line-height: 28px;
      color: #222222;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
    }
    .tips-band {
      position: relative;
      left: -48px;
      width: calc(100% + 96px);
      font-size: 14px;
      text-align: center;
      color: #666666;
      margin: 8px 0 32px;
      padding: 0 48px;
    }

    .local-bar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 32px;
      &__container {
        display: inline-flex;
        align-items: center;
        margin-bottom: 12px;
        color: #222;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  &__options {
    min-height: 32px;
    display: flex;
    flex-wrap: wrap;
    li {
      border: 1px solid #e5e5e5;
      height: 32px;
      border-radius: 16px;
      text-align: center;
      margin-bottom: 12px;
      margin-right: 12px;
      cursor: pointer;

      &.active {
        border-color: #222;
      }

      .option-text {
        overflow: hidden; /* 隐藏超出的内容 */
        white-space: nowrap; /* 保持内容在一行显示 */
        text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
        font-size: 14px;
        padding: 0 12px;
        line-height: 32px;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__success-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 225px;
    .icon {
      font-size: 96px;
      line-height: 1;
      color: #198055;
    }
    h2 {
      font-size: 16px;
      line-height: 20px;
      margin-top: 8px;
      color: #222;
      font-weight: 700;
      text-transform: capitalize;
    }
    h3 {
      font-size: 14px;
      color: #222;
      line-height: 18px;
      margin-top: 8px;
      font-weight: 400;
      text-transform: capitalize;
    }
    p {
      font-size: 14px;
      line-height: 17px;
      margin: 32px 0 8px;
    }
  }
}
</style>
  
