import modules from './modules'
import DetailPrefetch from 'public/src/pages/detail/main/utils/DetailPrefetch.js'

/**
 * @description BTF 数据流主入口
 */

const actions = {
  /**
   * @description 初始化 BTF 数据流
   * @param {*} 
   */
  btf_init({ dispatch, rootGetters = {} }) {
    // dispatch('xxxxx', DetailPrefetch.use('xxxxx'))
    // .
    // .
    // .
    // more...
    const screenAbtTspConfig = rootGetters['screenAbtTspConfig'] || {} // TODO融合分支需替换
    const is_need_combo_buy_temp = screenAbtTspConfig?.buytogethernew?.param?.buytogethernew === 'show'
    dispatch('GoodsDetailCccXBanner/init_ccc_x_banner', DetailPrefetch.use('CCCXBanner'))
    dispatch('GoodsDetailNewOutfit/init_new_outfit', DetailPrefetch.use('NewOutfitInfo'))
    dispatch('GoodsDetailPictures/init_detail_pictures_info', DetailPrefetch.use('DetailsPicturesInfo'))
    dispatch('GoodsDetailStyleGallery/init_buyer_show_floor_info', DetailPrefetch.use('BuyerShowFloorInfo'))
    // 组合购
    if(is_need_combo_buy_temp) {
      dispatch('ComboBuy/init_combo_buy', DetailPrefetch.use('ComboBuy'))
    }
  },
  btf_update({ dispatch }) { // 切色块时触发 还没想好怎么更新..
    dispatch('btf_init')
  }
}

export default {
  namespaced: true,
  actions,
  modules,
}
