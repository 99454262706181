<template>
  <div class="size-feedback">
    <div
      class="size-feedback__title"
      @click="onSizeFeedbackBtnClick"
    >
      {{ langMap.SHEIN_KEY_PC_22268 }}
      {{ langMap.SHEIN_KEY_PC_22259 }}
      <Icon
        name="sui_icon_more_right_18px"
        size="12"
        color="#666"
        class="size-feedback__icon"
      />
    </div>
    <SizeFeedBack
      ref="dialogRef"
      :goods-id="goodsId"
      :lang-map="langMap"
      :config="config"
      :size-unit="sizeUnit"
      @handle-open-find-similar="handleOpenFindSimilar"
    />
  </div>
</template>
<script>
export default {
  name: 'SizeFeedBackEnter'
}
</script>
<script setup>
import { ref } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SizeFeedBack from './SizeFeedBack.vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
const props = defineProps({
  goodsId: {
    type: String,
    default: ''
  },
  mallCode: {
    type: String,
    default: ''
  },
  country: {
    type: String,
    default: ''
  },
  config: {
    type: Object,
    default: () => ({})
  },
  langMap: {
    type: Object,
    default: () => ({})
  },
  sizeUnit: {
    type: String,
    default: '',
  },
  productIntroData: {
    type: Object,
    default: () => {}
  },
})
const dialogRef = ref(null)
const onSizeFeedbackBtnClick = () => {
  dialogRef.value?.show?.({
    goods_id: props.goodsId,
    mall_code: props.mallCode,
    userSwitchLocalCountry: props.country
  })
  daEventCenter.triggerNotice({
    daId: '1-8-4-9',
    extraData: {
      goods_id: props?.goodsId,
    }
  })
}
let SimilarPopover = {}
if (typeof window !== 'undefined') {
  import('public/src/pages/common/similar_products').then(res => {
    SimilarPopover = res.default
  })
}
const store = useStore()
const handleOpenFindSimilar = (selectedSize) => {
  SimilarPopover.showModal(props.productIntroData?.detail, {
    similarFrom: 'out_of_stock',
    filterSize: selectedSize?.key || '',
    updateExposedList: store.dispatch('updateExposedList')
  })
}
</script>

<style lang="less" scoped>
.size-feedback {
  position: relative;
  font-size: 12px;
  margin-bottom: 15px;
  display: flex;
  &__title {
    cursor: pointer;
    color: #666;
  }

  &__icon {
    transform: rotate(0deg) /* rtl:rotate(180deg) */;
  }
}
</style>
