<script>
export default {
  name: 'SizeSelectList'
}
</script>
<script setup>
import { defineProps } from 'vue'
import SizeSelectItem from '../SizeSelectItem/index.vue'

defineProps({
  saleAttrOrderMap: {
    type: Object,
    default: () => null,
  },
  hotLabelMap: {
    type: Object,
    default: () => null,
  },
  skcSaleAttr: {
    type: Array,
    default: () => ([]),
  },
  relatedLocalSize: {
    type: Object,
    default: () => ({}),
  },
  sizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  notSelectedTipsAnim: {
    type: Boolean,
    default: false,
  },
  localSizeComputed: {
    type: Object,
    default: () => ({}),
  },
  constant: {
    type: Object,
    default: () => ({}),
  },
  heightSizeObject: {
    type: Object,
    default: () => ({}),
  },
  sizePopoverPlacemen: {
    type: Array,
    default: () => [],
  },
  bubbleInfoComputed: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  },
  notSelectedTips: {
    type: Object,
    default: () => ({}),
  },
  foldBtn: {
    type: Array,
    default: () => [],
  },
  foldIndex: {
    type: Number,
    default: -1,
  },
  isAllSoldOut: {
    type: Boolean,
    default: false,
  },
  country: {
    type: String,
    default: '',
  },
  sizeGroupPosition: {
    type: String,
    default: '',
  },
  hideOneSize: {
    type: Boolean,
    default: false,
  },
  handleOpenFold: {
    type: Function,
    default: () => {},
  },
  showSizeGroupAfterSize: {
    type: Boolean,
    default: false,
  },
  onlyRelatedGoods: {
    type: Array,
    default: () => [],
  },
  isNewSizeLocal: {
    type: Boolean,
    default: false,
  },
  tipsReady: {
    type: Boolean,
    default: false,
  },
  hideDisableAttrId: {
    type: String,
    default: '',
  },
})
</script>

<template>
  <SizeSelectItem
    v-for="(item, index) in skcSaleAttr"
    :key="item.attr_id"
    :sale-attr-order-map="saleAttrOrderMap"
    :hot-label-map="hotLabelMap"
    :skc-sale-attr="skcSaleAttr"
    :item="item"
    :index="index"
    :constant="constant"
    :related-local-size="relatedLocalSize"
    :size-info-index="sizeInfoIndex"
    :not-selected-tips-anim="notSelectedTipsAnim"
    :local-size-computed="localSizeComputed"
    :height-size-object="heightSizeObject"
    :size-popover-placemen="sizePopoverPlacemen"
    :bubble-info-computed="bubbleInfoComputed"
    :current-selected-country="currentSelectedCountry"
    :not-selected-tips="notSelectedTips"
    :fold-btn="foldBtn"
    :fold-index="foldIndex"
    :is-all-sold-out="isAllSoldOut"
    :country="country"
    :is-new-size-local="isNewSizeLocal"
    :tips-ready="tipsReady"
    :size-group-position="sizeGroupPosition"
    :hide-one-size="hideOneSize"
    :handle-open-fold="handleOpenFold"
    :show-size-group-after-size="showSizeGroupAfterSize"
    :only-related-goods="onlyRelatedGoods"
    :hide-disable-attr-id="hideDisableAttrId"
  />
</template>
