<template>
  <div class="productintro-wraning">
    <!-- 玩具警告语 -->
    <ToyWarnList
      v-if="showToyWarning"
      :list="warningTextList"
      :language="language"
    />
    <!-- GPSR欧盟责任人 & 制造商 -->
    <div
      v-if="showToyResponsibleManufacturer"
      class="productintro-wraning__link"
      @click="handleDialogOpen"
    >
      <p class="productintro-wraning__link-text">
        {{ language.SHEIN_KEY_PC_35334 || 'Safety information and contacts' }}
      </p>
      <Icon
        name="sui_icon_more_right_18px"
        size="12px"
      />
    </div>

    <!-- detail弹窗 -->
    <SDialog
      v-model:visible="show"
      append-to-body
      :show-close="true"
      class="productintro-wraning__dialog"
    >
      <template #title> 
        <p class="productintro-wraning__dialog-title">
          {{ language.SHEIN_KEY_PC_35334 || 'Safety information and contacts' }}
        </p>
      </template>
      <div class="productintro-wraning__dialog-content">
        <div
          v-if="manuFactureInfo"
          class="productintro-wraning__dialog-nav"
        >
          <div
            class="productintro-wraning__dialog-nav-item"
            @click="clickNav(1)"
          >
            <p
              class="productintro-wraning__dialog-nav-item-text"
              :style="{color: nav === 1 ? '#000' : ''}"
            >
              {{ language.SHEIN_KEY_PC_35335 || 'EU Responsible Person' }}
            </p>
            <div
              v-show="nav === 1"
              class="productintro-wraning__dialog-nav-item-focusing"
            ></div>
          </div>
          <div
            class="productintro-wraning__dialog-nav-item"
            @click="clickNav(2)"
          >
            <p
              class="productintro-wraning__dialog-nav-item-text"
              :style="{color: nav === 2 ? '#000' : ''}"
            >
              {{ language.SHEIN_KEY_PC_35439 || 'Manufacture' }}
            </p>
            <div
              v-show="nav === 2"
              class="productintro-wraning__dialog-nav-item-focusing"
            ></div>
          </div>
        </div>
        <div
          v-show="nav === 1"
          class="productintro-wraning__dialog-content-detail"
        >
          <p class="productintro-wraning__dialog-content-detail-subtit">
            {{ dialogInfo.tipsTitle }}
          </p>
          <p class="productintro-wraning__dialog-content-detail-text">
            {{ dialogInfo.tipsText }}
          </p>
          <template v-if="!dialogInfo.noEuRespPersonInfo">
            <p
              v-if="dialogInfo.nameValue"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.nameKey }}:&ensp;
              {{ dialogInfo.nameValue }}
            </p>
            <p
              v-if="dialogInfo.addressValue"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.addressKey }}:&ensp;
              {{ dialogInfo.addressValue }}
            </p>
            <p
              v-if="dialogInfo.postalKey"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.postalKey }}:&ensp;
              {{ dialogInfo.postalValue }}
            </p>
            <p
              v-if="dialogInfo.telephoneNumberValue"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.telephoneNumberKey }}:&ensp;
              {{ dialogInfo.telephoneNumberValue }}
            </p>
            <p
              v-if="dialogInfo.emailAddressValue"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.emailAddressKey }}:&ensp;
              {{ dialogInfo.emailAddressValue }}
            </p>
            <p
              v-if="dialogInfo.customerServiceValue"
              class="productintro-wraning__dialog-content-detail-text"
            >
              {{ dialogInfo.customerServiceKey }}:&ensp;
              <a
                style="color:#2D68A8;cursor:pointer"
                target="_blank"
                :href="dialogInfo.customerServiceValue"
              >{{ dialogInfo.customerServiceValue }}</a>
            </p>
          </template>
          <template v-else>
            <p class="productintro-wraning__dialog-content-detail-text">
              {{ dialogInfo.noEuRespPersonInfo.tipsTitle }}:&ensp;
              <a
                style="color:#2D68A8;cursor:pointer"
                target="_blank"
                :href="dialogInfo.noEuRespPersonInfo.customerUrl"
              >{{ dialogInfo.noEuRespPersonInfo.tipsTitle }}</a>
            </p>
          </template>
        </div>

        <div
          v-show="nav === 2"
          class="productintro-wraning__dialog-content-detail"
        >
          <p class="productintro-wraning__dialog-content-detail-subtit">
            {{ language.SHEIN_KEY_PC_35336 || 'Manufacturer Information' }}
          </p>
          <p class="productintro-wraning__dialog-content-detail-text">
            {{ language.SHEIN_KEY_PC_35337 || 'Manufacturer information includes the address and information related to the manufacturer of the product.' }}
          </p>
          <p
            v-if="manuFactureInfo && manuFactureInfo.nameValue"
            class="productintro-wraning__dialog-content-detail-text"
          >
            {{ manuFactureInfo.nameKey }}:&ensp;
            {{ manuFactureInfo.nameValue }}
          </p>
          <p
            v-if="manuFactureInfo && manuFactureInfo.addressValue"
            class="productintro-wraning__dialog-content-detail-text"
          >
            {{ manuFactureInfo.addressKey }}:&ensp;
            {{ manuFactureInfo.addressValue }}
          </p>
          <p
            v-if="manuFactureInfo && manuFactureInfo.postalKey"
            class="productintro-wraning__dialog-content-detail-text"
          >
            {{ manuFactureInfo.postalKey }}:&ensp;
            {{ manuFactureInfo.postalValue }}
          </p>
          <p
            v-if="manuFactureInfo && manuFactureInfo.telephoneNumberValue"
            class="productintro-wraning__dialog-content-detail-text"
          >
            {{ manuFactureInfo.telephoneNumberKey }}:&ensp;
            {{ manuFactureInfo.telephoneNumberValue }}
          </p>
          <p
            v-if="manuFactureInfo && manuFactureInfo.emailAddressValue"
            class="productintro-wraning__dialog-content-detail-text"
          >
            {{ manuFactureInfo.emailAddressKey }}:&ensp;
            {{ manuFactureInfo.emailAddressValue }}
          </p>
        </div>
      </div>
    </SDialog>
  </div>
</template>

<script setup name="ProductIntroWarn">
import { ref, toRefs, computed, defineAsyncComponent } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { useMapState } from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'

const ToyWarnList = defineAsyncComponent(() => import(/* webpackChunkName: "ToyWarnList" */ './ToyWarnList.vue'))

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  screenAbtTspConfig: {
    type: Object,
    default: () => ({})
  },
})

const { 
  productIntroData,
} = useMapState([
  'productIntroData',
])

const { goods_id } = toRefs(productIntroData?.value?.detail)
const {
  toyWarningWord,
} = toRefs(productIntroData?.value?.productMiscInfo)

const warningTextList = computed(() => toyWarningWord.value?.warningTextList || [])
const goodsdetailscompliance = props.screenAbtTspConfig?.goodsdetailscompliance || {}
const showToyWarning = computed(() => goodsdetailscompliance.param?.toy_warning_tips === 'on' && warningTextList.value.length)
const showToyResponsibleManufacturer = computed(() => {
  const siteList = ['fr', 'es', 'de', 'it', 'nl', 'se', 'eur', 'pt', 'ch', 'pl', 'roe', 'euqs', 'seu', 'at', 'ro']
  const { SiteUID } = gbCommonInfo
  if (!siteList.includes(SiteUID)) return false
  return goodsdetailscompliance.param?.gpsr_tips === 'on'
})
const show = ref(false)
const dialogInfo = ref({})
const nav = ref(1)
const clickNav = (index) => {
  nav.value = index
}

const handleDialogOpen = async () => {
  const res = await getEurPerson()
  dialogInfo.value = res.info
  nav.value = 1
  show.value = true
}

const manuFactureInfo = computed(() => {
  return dialogInfo.value?.manuFactureInfo
})

const getEurPerson = async () => {
  let res = await schttp({
    url: '/product/get_eu_resp_person',
    params: {
      goods_id: goods_id.value
    },
    useBffApi: true,
  })
  return res
}


</script>

<style lang="less">
.ellipsis(@clamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @clamp;
}
.productintro-wraning {
  font-size: 12px;
  color: #222;

  &__link {
    display: flex;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0px;
    cursor: pointer;
    color: #666;
    margin-top: 5px;
  }
  &__link-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  .pointer {
    cursor: pointer;
  }



}

.productintro-wraning__dialog {
  .sui-dialog__wrapper {
    width: 530px !important; /* stylelint-disable-line declaration-no-important */
  }
}

.productintro-wraning__dialog-content {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
}
.productintro-wraning__dialog-nav {
  display: flex;
  justify-content: space-between;
}
.productintro-wraning__dialog-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productintro-wraning__dialog-nav-item {
  width: 221px;
  height: 45px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

}
.productintro-wraning__dialog-nav-item-text {
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0px;
  text-align: center;
  color: #767676;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productintro-wraning__dialog-nav-item-focusing {
  position: absolute;
  top: 34px;
  background: #000;
  width: 40px;
  height: 2px;
}

.productintro-wraning__dialog-content-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: SF Pro;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0px;
  color: #666;
  margin-top: 8px;
}
.productintro-wraning__dialog-content-detail-subtit {
  font-family: SF Pro;
  font-weight: 590;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0px;
  color: #000;
  text-align: left;
}
.productintro-wraning__dialog-content-detail-text {
  font-family: SF Pro;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0px;
  color: #666;
  text-align: left;
  line-height: 14px;
  margin-top: 12px;
}
</style>
