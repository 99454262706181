<template>
  <div
    class="s-swiper-container s-swiper-container-vertical swiper-no-swiping store__atmosphere"
  >
    <swiper-container
      ref="swiper"
      init="false"
      destroy-on-disconnected="false"
      class="s-swiper-wrapper store__atmosphere-box"
    >
      <swiper-slide
        v-for="(item, key) in atmosphereData"
        :key="key"
        class="s-swiper-slide swiper-slide store__atmosphere-item"
        :class="position === 'showscroll' ? 'store__atmosphere-scroll' : ''"
      >
        <template v-if="item.isStoreFollow">
          <slot name="followInfo"></slot>
        </template>
        <template v-else>
          <img
            src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/0e/172258728695cce69897541b23a1ed7cc0d69f8fdb.png"
            class="store__atmosphere-dot"
          />
          <img
            src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/35/172258730293a70b12875b95870901d1fe46e62169.png"
            class="store__atmosphere-profile"
          />
          <span 
            class="store__atmosphere-text" 
            v-html="handleItemText(item)"
          ></span>
        </template>
      </swiper-slide>
    </swiper-container>
  </div>
</template>
  
<script>
/**
   * @component 人气氛围信息滚动展示框
   */
import { mapGetters, mapState } from 'vuex'
import { Autoplay } from 'swiper/modules'
import { template } from '@shein/common-function'


export default {
  name: 'StoreAtmosphereSwiper',
  props: {
    hasFollowInfo: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      atmosphereSwiper: null,
    }
  },
  computed: {
    ...mapState(['realTimeBffReady', 'updateStoreReady']),
    ...mapGetters(['storeAtmosphereInfo']),
    updateDataReady() {
      return this.realTimeBffReady && this.updateStoreReady
    },
    atmosphereData() {
      let data = this.storeAtmosphereInfo
      if(this.hasFollowInfo){
        let result = []
        let n = data[0]?.popTypeLength || 0
        for (let i = 0; i < data.length; i++) {
          // 每隔n项插入店铺评分信息
          if (i % n === 0) {
            result.push({ isStoreFollow: true })
          }
          result.push(data[i])
        }
        return result
      }
      return data
    },
    handleItemText() {
      return (item) => {
        const p1 = item?.nameTip ? `<span>${item?.nameTip}</span>` : ''
        const p2 = item?.timeTip ? `<span>${item?.timeTip}</span>` : ''
        let text = template(p1, p2, item.multiLanguage)
        return text
      }
    },
  },
  watch: {
    storeAtmosphereInfo: {
      handler(val) {
        // this.atmosphereSwiper?.destroy?.()
        // this.atmosphereSwiper = null
        if (!val?.length) return
        this.$nextTick(() => {
          this.initSwiper()
          this.atmosphereSwiper?.update()
        })
      },
      immediate: true,
    },
    updateDataReady: { // 店铺数据更新重新更新swiper
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.atmosphereSwiper?.update?.()
          })
        }
      },
    },
  },
  mounted() {
  },
  unmounted() {
    this.atmosphereSwiper?.destroy?.()
  },
  methods: {
    initSwiper() {
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return

      const swiperParams = {
        autoplay: {
          delay: this.storeAtmosphereInfo?.length > 1 ? 1500 : 0,
          disableOnInteraction: false,
        },
        modules: [Autoplay],
        speed: 1000,
        loop: true,
        direction: 'vertical',
        observer: true,
        observeParents: true,
        noSwiping: true,
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.atmosphereSwiper = swiperEl.swiper
    },
  },
}
</script>
  
<style lang="less">
.store {
  &__atmosphere {
    position: relative;
    overflow: hidden;
    margin: 0;
    height: 16px;
    .s-swiper-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: content-box;
    }
  }

  &__atmosphere-box {
    width: 100%;
  }

  &__atmosphere-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__atmosphere-scroll{
    justify-content: flex-start;
  }

  &__atmosphere-dot{
    width: 8px;
    height: 8px;
    flex-shrink: 0;
  }
  &__atmosphere-profile{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin: 0 6px;
  }
  &__atmosphere-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    font-style: normal;
    color: #000;
    & > span {
      color: #767676;
    }
  }


  
}
.store__atmosphere{
   .s-swiper-slide{
    opacity: 0;
    transition: opacity 2s linear 0s;
  }
   .swiper-slide-active{
    opacity: 1;
  }
}
</style>

