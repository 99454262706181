<template>
  <div
    v-expose="{ id: '1-8-6-118' }"
    class="product-intro__freeshipping-item full-line"
    da-event-click="1-8-6-14"
    @click="clickReturn"
  >
    <div class="product-intro__freeshipping-icon">
      <sui_icon_return_48px 
        size="24"
        color="#198055"
      />
    </div>
    <div>
      <div class="product-intro__freeshipping-title">
        <span
          tabindex="0"
          v-html="shippingReturnPolicy.title"
        ></span>
        <Icon
          class="product-intro__freeshipping-more"
          name="sui_icon_doubt_circle_14px_1"
          color="#999"
          size="14px"
        />
      </div>
      <div class="product-intro__freeshipping-desc">
        <span class="gray">
          {{ content }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, sui_icon_return_48px } from '@shein-aidc/icon-vue3'

export default {
  name: 'ShippingReturnPolicy',
  components: { sui_icon_return_48px, Icon },
  props: {
    shippingReturnPolicy: {
      type: Object,
      default: () => {
        title: 'xxx'
      }
    },
  },
  computed: {
    content(){
      return this.shippingReturnPolicy.subTitle || this.shippingReturnPolicy.learnMore
    }
  },
  methods: {
    clickReturn() {
      this.$emit('clickReturn')
    }
  },
  emits: ['clickReturn']
}
</script>
