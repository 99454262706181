<template>
  <div
    v-if="goodsComponent || ingredientsImageList.length"
    class="product-intro__ingredients j-expose__product-intro__ingredients"
  >
    <div
      v-enterkey
      class="product-intro__ingredients-head j-expose__product-intro__ingredients-head"
      @click="handleFold"
    >
      <span class="j-expose__product-intro__ingredients-name">{{
        language.SHEIN_KEY_PC_17597
      }}</span>
      <sui_icon_min_16px
        v-if="unfold.includes('Ingredients')"
        size="16px"
        class="head-icon"
      />
      <sui_icon_add_16px
        v-else
        size="16px"
        class="head-icon"
      />
    </div>
    <ClientOnly>
      <div v-show="unfold.includes('Ingredients')">
        <template v-if="isFilterIngredients">
          <PrdImageAttrGroup
            v-for="(group, index) in ingredientsImageList"
            :key="index"
            :group="group"
            :no-title="true"
            :no-bottom="true"
          />
          <div class="product-intro__ingredients-wrap">
            <span
              ref="textElement"
              class="product-intro__ingredients-outer"
            >
              {{ goodsComponent }}
            </span>
            <template v-if="isOverflow">
              <div class="product-intro__ingredients-fixd">
                <div class="split"></div>
                <div
                  class="text"
                  @click="handleMore"
                >
                  ...{{ language.SHEIN_KEY_PC_15953 }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <div
          v-else
          :style="{ 'max-height': '195px', overflow: 'hidden' }"
        >
          <div
            ref="Ingredients"
            class="product-intro__ingredients-panel"
            @click="handleMore"
          >
            <span
              v-for="(item, index) in goodsComponentOuter"
              ref="itemWord"
              :key="index"
              v-html="item + ' '"
            ></span>
          </div>
        </div>
      </div>
    </ClientOnly>
    <ClientOnly>
      <s-dialog
        v-model:visible="showModal"
        :type="'W720'"
        :append-to-body="true"
        :show-close="true"
      >
        <template #title>
          {{ language.SHEIN_KEY_PC_17597 }}
        </template>
        <div
          class="product-intro__ingredients-text"
          v-html="goodsComponent"
        ></div>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/arrow-down.svg'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_min_16px, sui_icon_add_16px } from '@shein-aidc/icon-vue3'
import PrdImageAttrGroup from 'public/src/pages/goods_detail_v2/components/PrdImageAttrGroup.vue'
export default {
  name: 'ProductIntroIngredients',
  components: {
    ClientOnly,
    sui_icon_min_16px,
    sui_icon_add_16px,
    PrdImageAttrGroup,
  },
  data() {
    return {
      visibilityText: [],
      showModal: false,
      isOverflow: false,
    }
  },

  computed: {
    ...mapState(['productIntroData', 'unfold', 'language']),
    ...mapGetters(['isFilterIngredients', 'ingredientsImageList']),
    goodsComponent() {
      const goodsComponent =
        this.productIntroData?.getBeautyGoodsDesc?.goodsComponent || ''
      return goodsComponent.replace(/\n/g, ' <br> ')
    },
    goodsComponentOuter() {
      return this.visibilityText.length
        ? this.visibilityText
        : this.goodsComponent.split(/\s+/)
    },
  },

  watch: {
    'productIntroData.getBeautyGoodsDesc'() {
      if (this.unfold.includes('Ingredients')) {
        this.setShowMoreMark()
      }
    },
    unfold: {
      handler(newVal) {
        if (newVal.includes('Ingredients')) {
          this.setShowMoreMark()
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations(['assignState']),
    /**
     * 收起展开
     */
    handleFold() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-52',
      })
      let updatedArray = []
      if (this.unfold.includes('Ingredients')) {
        updatedArray = this.unfold.filter(item => item !== 'Ingredients')
      } else {
        updatedArray = [...this.unfold, 'Ingredients']
      }
      this.assignState({
        unfold: updatedArray,
      })
    },
    handleMore(e) {
      if (
        ['product-intro__ingredients-more', 'text'].includes(e.target.className)
      ) {
        this.showModal = true
      }
    },
    setShowMoreMark() {
      this.$nextTick(() => {
        if (this.isFilterIngredients) {
          const textElement = this.$refs.textElement

          // 获取实际内容的高度和最大高度（三行）
          const maxHeight =
            parseFloat(getComputedStyle(textElement).lineHeight) * 3 // 三行高度
          this.isOverflow = textElement.scrollHeight > maxHeight // 检测溢出
          return
        }

        if (this.$refs.Ingredients.offsetHeight > 195) {
          let maxTop = -9999
          let line = 0
          let resultIndex = 0
          for (let i = 0; i < this.$refs.itemWord.length; i++) {
            const item = this.$refs.itemWord[i]
            if (item.offsetTop > maxTop) {
              if (line >= 1) {
                line += Math.round((item.offsetTop - maxTop) / 18)
              } else {
                line = 1
              }
              maxTop = item.offsetTop
            }
            // 第十行的最后一个单词
            if (line > 10) {
              resultIndex = i - 1
              break
            }
          }
          let visibilityText = []
          let str = ''
          for (let i = resultIndex; i >= 0; i--) {
            if (i == resultIndex && this.$refs.itemWord[i].offsetHeight > 18) {
              continue
            }
            str += this.goodsComponentOuter[i]
            if (str.length >= 12) {
              visibilityText = this.goodsComponentOuter.slice(0, i)
              break
            }
          }
          this.visibilityText = visibilityText.concat(
            '<span class="product-intro__ingredients-more">...' +
              this.language.SHEIN_KEY_PC_15953 +
              '</span>',
          )
        }
      })
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__ingredients-text {
    word-break: break-word;
    font-size: 14px;
    line-height: 16px;
    color: #222;
    text-transform: lowercase;
  }
  &__ingredients-panel {
    padding: 15px 0 0;
    word-break: break-all;
    line-height: 18px;
    font-size: 12px;
  }
  &__ingredients-outer {
    /* 限制文本最大显示高度 */
    display: block; /* 确保文本块是块级容器 */
    max-height: calc(18px * 3); /* 假设行高为1.2em，最多显示3行 */
    line-height: 1.2em; /* 设置行高 */
    overflow: hidden; /* 超出部分隐藏 */
    line-height: 1.5em; /* 设置行高，确保视觉一致性 */
    font-size: 12px;
    text-transform: lowercase;
  }
  &__ingredients-more {
    color: #1860a7;
    cursor: pointer;
    font-size: 12px;
    margin: 5px 0 15px;
  }
  &__ingredients-wrap {
    position: relative;
  }
  &__ingredients-fixd {
    display: inline-flex;
    flex-wrap: nowrap;
    position: absolute;
    height: 14px;
    bottom: 0;
    right: 0;
    color: #1860a7;
    cursor: pointer;
    font-size: 12px;
    .split {
      height: 100%; /* 高度覆盖文本行的高度 */
      width: 10px; /* 渐变宽度为 10px */
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      ); /* 从白到透明的渐变 */
      pointer-events: none; /* 禁止影响鼠标点击 */
    }
    .text {
      background-color: #fff;
    }
  }
}

.product-intro__ingredients-text::first-letter {
  text-transform: uppercase; /* 将首字母转为大写 */
}

.product-intro__ingredients-outer::first-letter {
  text-transform: uppercase; /* 将首字母转为大写 */
}
</style>
