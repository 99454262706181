<script>
export default {
  name: 'SizeSelectItemRelated'
}
</script>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { defineProps, inject, computed, ref, watch } from 'vue'
import ProductCardPriceSimpleAutoComplianceMode from 'public/src/pages/goods_detail_v2/components/ProductCardPriceSimpleAutoComplianceMode.vue'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail_v3/utils/productDetail.js'
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  constant: {
    type: Object,
    default: () => ({}),
  },
  relatedLocalSize: {
    type: Object,
    default: () => ({}),
  },
  sizePopoverPlacemen: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  }
})
const sizeRefs = inject('sizeRefs')
const setSizeRefs = inject('setSizeRefs')
const config = inject('config')
const language = inject('language')
const commonFn = inject('commonFn')
const currentTabLocalIsActive = inject('currentTabLocalIsActive')
const plusSizeRule = computed(() => props.relatedLocalSize?.plusSizeRule)
const relateGood = computed(() => props.relatedLocalSize?.relateGood)
const relateGoodType = computed(() => props.relatedLocalSize?.relateGoodType)
const relateGoodsName = computed(() => props.relatedLocalSize?.relateGoodsName)
const idx = computed(() => relateGoodType.value === 0 ? 0 : 999)
let hasAtomInitial = ref(false)
let relatedGoodsList = ref([])
const relateSizeMap = computed(() => {
  return relateGood.value?.sizeText?.map?.(goods => {
    let country = props.currentSelectedCountry
    if(config?.showOnlyOneLocalSize){
      country = currentTabLocalIsActive.value ? config?.localSizeList?.country_code : ''
    }
    const plus = plusSizeRule.value[country]?.find?.(({ name }) => {
      return name == goods && goods != 'one-size'
    })
    if (plus) {
      const shouldNoDefaultSizeAbt = ['local_size_no_default', 'default_size_no_default'].includes(config?.defaultlocalsize)
      if (shouldNoDefaultSizeAbt) return plus.correspond
      return `${plus.correspond}${config?.shouldWeakenDefaultSize ? `<span style="color: #959595; font-size: 12px;">(${goods})</span>` : `(${goods})`}`
    }
    return goods
  }) || {}
})
const onBeforeOpen = async () => {
  if(hasAtomInitial.value) return
  relatedGoodsList.value = [relateGood.value]
  await itemSer.getAtomicInfoNew({
    goods: relatedGoodsList.value,
    fields: {
      baseInfo: 1,
      mallInfo: 1,
      pricesV2: 1
    },
    isPaid: props.constant.isPaidUser,
  })
  await getDetailRecPreTreat({ products: relatedGoodsList.value })
  // eslint-disable-next-line require-atomic-updates
  hasAtomInitial.value = true
  Object.values(sizeRefs.value['popoverRef'] || {}).forEach(_ => _.calcPopoverPosition?.())
  sizeRefs['popoverRef']?.forEach(_ => _.calcPopoverPosition?.())
}
const showQuickviewModalTouch = (e, isEnter, data) => {
  if (config.isMobile) {
    commonFn.showQuickviewModal(e, isEnter, data, 'auto')
  }
}
watch(() => relateGood.value.goods_id, ()=> {
  hasAtomInitial.value = false
})
</script>

<template>
  <s-popover     
    :ref="setSizeRefs('popoverRef', idx + 'pluseSize')"
    :key="idx"
    :class="[
      'relate-good',
      'product-intro__size-radio-spopover',
      `j-product-intro__size-radio-spopover_${item.attr_id}_index${idx}`,
    ]"
    :placemen="sizePopoverPlacemen[idx]"
    :delay-render="300"
    trigger="hover"
    :append-to-body="true"
    :empty-un-show="true"
    @before-open="onBeforeOpen"
  >
    <template #reference>
      <div
        v-enterkey
        class="product-intro__size-radio"
        tabindex="0"
        @touchstart="showQuickviewModalTouch($event, true, relateGood)"
        @click="commonFn.showQuickviewModal($event, true, relateGood)"
      >
        <div class="product-intro__size-radio-inner">
          <span
            v-for="(subItem, i) in relateGood.sizeText"
            :key="i"
            v-html="relateSizeMap[i]"
          ></span>
          <Icon
            :class="{ 'to-right': config.from == 'detail' }"
            name="sui_icon_paly_40px"
            size="14px"
            :custom-style="{ verticalAlign: '-3px'}"
          />
        </div>
      </div>
    </template>
    <div class="product-intro__size-popover-content product-intro__sizegroup-popover-content">
      <template v-if="hasAtomInitial">
        <div
          class="product-intro__size-popover-img"
          :style="{
            backgroundImage: `url(${relatedGoodsList[0].goods_thumb})`,
          }"
        ></div>
        <div class="product-intro__size-popover-wrap">
          <div class="product-intro__size-popover-stitle">
            {{ language.SHEIN_KEY_PC_17358 }}
          </div>
          <div class="product-intro__size-popover-gtitle">
            {{ relateGoodsName }}
          </div>
          <div class="product-intro__size-popover-gprice">
            <ProductCardPriceSimpleAutoComplianceMode
              :goods-info="relatedGoodsList[0]"
              :is-paid="constant.isPaidUser"
              :hide-suggested-tip-popover="true"
              :show-fr-de-rrp-price="true"
              :config="{
                showS3VipPriceOnSale: config?.isS3newPrice
              }"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="product-intro__size-popover-loading">
          <div
            class="la-ball-pulse la-ball-pulse-black"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
    </div>
  </s-popover>
</template>

<style lang="less">
.product-intro {
  &__sizegroup-popover-content {
    cursor: pointer;
    min-width: 248px;
    min-height: 56px;
  }
  &__size-popover-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 248px;
    min-height: 56px;
    & .la-ball-pulse >div {
      width: 8px;
      height: 8px;
    }
  }
  &__size-popover-gprice {
    .product-card__price-suggested {
      font-size: 11px;
      font-family: Arial;
    }
  }
}
</style>
